/* eslint-disable react/no-unknown-property */
import { Icon, Spin, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import * as CandidateActions from '../../../Actions/CandidateActions';
import * as JobActions from '../../../Actions/JobActions';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';
import { getSubSegmentSourcedJobIdSharedUsers } from '../../../Reducers/JobReducer';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import styles from './NewCandidateShortlistReject.module.scss';

const mapDispatchToProps = {
  recommendCandidate: CandidateActions.recommendCandidate,
  getJobSharedUsers: JobActions.getJobSharedUsers,
};
const mapStateToProps = (state, props) => ({
  subSegmentSourcedJobIdSharedUsers: getSubSegmentSourcedJobIdSharedUsers(state, { jobId: props.jobId }),
  currentUserDetails: getCurrentUserDetails(state),
});

const SubSegmentCandidateSourcedShortlistReject = props => {
  const { candidate, jobId, recommendCandidate, subSegmentSourcedJobIdSharedUsers = {}, currentUserDetails, openInNewTab, getJobSharedUsers, zIndex, placement } = props;

  const allSharedAndAssignedUser = [...(subSegmentSourcedJobIdSharedUsers.AssignedUsers || []), ...(subSegmentSourcedJobIdSharedUsers.SharedUsers || [])]
  const currentUserId = currentUserDetails.Id;
  const isCurrentUserSharedOrAssigned = allSharedAndAssignedUser.includes(currentUserId);
  const [recommendCandidateApiStatus, setRecommendCandidateApiStatus] = React.useState(null);


  const tooltipPlacement = placement || "topLeft"

  function saveCandidateStatus(candidateId, status) {
    const candidateData = JSON.parse(localStorage.getItem('candidateStatus')) || {};

    if (!candidateData[jobId]) {
      candidateData[jobId] = {};
    }
    candidateData[jobId][candidateId] = status;

    localStorage.setItem('candidateStatus', JSON.stringify(candidateData));
  }

  function getCandidateStatus(candidateId) {
    const candidateData = JSON.parse(localStorage.getItem('candidateStatus')) || {};

    if (candidateData[jobId] && candidateData[jobId][candidateId]) {
      return candidateData[jobId][candidateId];
    }
    return null;
  }

  React.useEffect(() => {
    if (openInNewTab) {
      getJobSharedUsers(jobId)
    }
  }, [])

  const changeCandidateShortlistedStatus = async e => {
    if (!isCurrentUserSharedOrAssigned)
      return
    e.stopPropagation();
    const candidateStatusForSourcedJob = getCandidateStatus(candidate.Id);
    const status = candidateStatusForSourcedJob === 'Shortlisted' ? 'Sourced' : 'Shortlisted';
    const candidatePayLoad = { ...candidate, jobId, candidateId: candidate.Id, status };
    setRecommendCandidateApiStatus('INPROGRESS');
    await recommendCandidate(candidatePayLoad, 'segment');
    localStorage.setItem(candidate.Id, status);
    saveCandidateStatus(candidate.Id, status);
    setRecommendCandidateApiStatus('COMPLETED');
  };

  const isShortlisted = getCandidateStatus(candidate.Id) === 'Shortlisted';
  const shorlistIcon = isShortlisted ? (
    <Icon type="like" style={{ fontSize: '32px', color: '#13c26b' }} theme="filled" />
  ) : (
    <Icon type="like" style={{ fontSize: '32px' }} />
  );
  const candidateShortlistIconTootlip = isShortlisted ? (
    <div>Candidate is shortlisted to Job Id - {jobId}</div>
  ) : (
    <div>Candidate will be shortlisted to Job Id - {jobId}</div>
  );

  const shortlistButtonTooltip = isCurrentUserSharedOrAssigned ? candidateShortlistIconTootlip : "Job is not shared with you, Please contact the job owner."

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={changeCandidateShortlistedStatus}
      onKeyPress={changeCandidateShortlistedStatus}
      className={styles.shorlistIconDiv}
      style={isCurrentUserSharedOrAssigned ? { cursor: "pointer" } : { cursor: "not-allowed", opacity: .3 }}
      sk-event-name={
        isShortlisted
          ? getEventNameByCandidateStatus(eventTypes.candidate.candidateShortlist.unshortlistCandidate, candidate)
          : getEventNameByCandidateStatus(eventTypes.candidate.candidateShortlist.shortlistCandidate, candidate)
      }
    >
      {recommendCandidateApiStatus === 'INPROGRESS' ? (
        <Spin />
      ) : (
        <Tooltip title={shortlistButtonTooltip} placement={tooltipPlacement} overlayStyle={{ zIndex: zIndex || 2, maxWidth: 'fit-content' }}>{shorlistIcon}</Tooltip>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubSegmentCandidateSourcedShortlistReject);
