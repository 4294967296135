import React from 'react';
import { connect } from 'react-redux';
import { Select, Form } from 'antd';
import debounce from 'lodash/debounce';
import styles from './CandidateTransfer.module.scss';
import { removeCommaFromEnd } from '../../Utils/TextUtils';
import * as JobActions from '../../Actions/JobActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import { getActiveTab } from '../../Reducers/JobCandidatesTabReducer';
import EnhancedCandidateTransferModal from './EnhancedCandidateTransferModal';
import { validateCandidateTransfer } from '../../Utils/FormValidators';
import * as SubSegmentActions from '../../Actions/SubSegmentActions';

const { Option } = Select;

const mapDispatchToProps = {
  fetchJobs: JobActions._fetchJobs,
  bulkCandidateTransfer: CandidateActions.bulkCandidateTransfer,
  fetchSubSegments: SubSegmentActions._fetchSubSegmentsList,
};
const mapStateToProps = state => {
  return {
    activeTab: getActiveTab(state),
  };
};
function CandidateTransfer(props) {
  const {
    candidateTransferIconClassName,
    onClickTransferIcon,
    isCandidateTransferModalVisible,
    fetchJobs,
    candidateIds,
    jobId,
    bulkCandidateTransfer,
    setCandidateTransferModalVisibility,
    form,
    tooltipTitle,
    invalidCandidateCallbackMessage,
    transferType,
    fillColor,
    version,
    isEnableCustomSelect,
    customSelectNumber,
    filterContextId,
    isAryaRecommendedSelectAllChecked,
    activeTab,
    candidateContext,
    clearBulkActions,
    fetchSubSegments,
    selectedSubSegmentId,
  } = props;

  const [actionName, setActionName] = React.useState('Copy');
  const [destinationName, setDestinationName] = React.useState('Job');
  const [options, setOptions] = React.useState([]);
  const [candidateTranserApiStatus, setCandidateTranserApiStatus] = React.useState();
  const [fetchJobsApiStatus, setFetchJobsApiStatus] = React.useState();
  const [subsegmentList, setSubsegmentList] = React.useState([]);
  const [fetchSubsegmentApiStatus, setFetchSubsegmentApiStatus] = React.useState();
  const selectPlaceholder = 'Enter a destination';
  const selectSubsegmentPlaceholder = 'Select Sub-segment (optional)';
  const isJobTransfer = candidateContext === 'segment' && destinationName.toLowerCase() === 'job';
  const isLimitExceededForJobTransfer = isJobTransfer && customSelectNumber > 100;

  const fetchJobsOrLists = async (searchTerm, _destinationName) => {
    const payload =
      _destinationName === 'Segment'
        ? {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            EntityType: 'Segment',
            isAssigned: true,
          }
        : {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            isAssigned: true,
          };
    const result = await fetchJobs(payload);
    const updateResult =
      candidateContext === 'segment' ? result?.Jobs ?? [] : (result?.Jobs ?? []).filter(job => job.JobId !== jobId);
    setOptions(
      (updateResult ?? []).map(job => ({
        value: job.JobId,
        text: `${job.JobTitle} (Id: ${version !== 'ats' || destinationName === 'Segment' ? job.JobId : job.JobCode}) ${
          destinationName === 'Job' ? '|' : ''
        } ${
          destinationName === 'Segment' || job.Location === undefined || job.Location === ''
            ? ''
            : removeCommaFromEnd(job.Location)
        } ${destinationName === 'Segment' || job.Location === undefined || job.Location === '' ? '' : ','} ${
          destinationName === 'Job' ? job.CountryCode : ''
        } `,
      }))
    );
  };

  const fetchSubsegmentsLists = async (segmentId, searchTerm) => {
    const result = await fetchSubSegments(segmentId, searchTerm, 0, 10);
    const updateResult = result?.Subsegments;
    setSubsegmentList(updateResult);
  };

  const onSearch = debounce(async (value, _destinationName) => {
    if (value.length) {
      setFetchJobsApiStatus('INPROGRESS');
      await fetchJobsOrLists(value, _destinationName);
      setFetchJobsApiStatus('COMPLETED');
    }
  }, 600);

  const onSubsegmentSearch = debounce(async (value, searchTerm) => {
    setFetchSubsegmentApiStatus('INPROGRESS');
    await fetchSubsegmentsLists(value, searchTerm);
    setFetchSubsegmentApiStatus('COMPLETED');
  }, 600);

  const onSelect = value => {
    setSubsegmentList([]);
    form.setFieldsValue({ destinationSubsegment: undefined });
    onSubsegmentSearch(value, '');
  };

  const onDeselect =(value) => {
    if(!value || value === ''){
      setSubsegmentList([]);
      form.setFieldsValue({ destinationSubsegment: undefined });
    }
  };

  const handleBulkTranfer = async _actionName => {
    form.validateFields(async (err, values) => {
      if (!err) {
        setCandidateTranserApiStatus('INPROGRESS');
        const filter = {
          candidateIds,
          jobId,
          destinationJobId: values.destinationJob,
          actionName: _actionName,
        };
        if (isAryaRecommendedSelectAllChecked) {
          filter.filterContextId = filterContextId;
          filter.candidateIds = undefined;
          if (isJobTransfer) {
            filter.ApplyCount = 100;
          }
        } else if (isEnableCustomSelect && customSelectNumber) {
          filter.filterContextId = filterContextId;
          filter.ApplyCount = isLimitExceededForJobTransfer ? 100 : customSelectNumber;
          filter.candidateIds = undefined;
        }
        if (activeTab === 'subsegment') {
          filter.subsegmentId = [selectedSubSegmentId];
        }
        if (values.destinationSubsegment) filter.destinationSubsegment = values.destinationSubsegment;
        await bulkCandidateTransfer(filter, transferType);
        setCandidateTranserApiStatus('COMPLETED');
        setCandidateTransferModalVisibility(false);
        if (clearBulkActions) {
          clearBulkActions();
        }
        setSubsegmentList([]);
        form.setFieldsValue({ destinationJob: undefined, destinationSubsegment: undefined });
      }
    });
  };

  const handleOnClose = () => {
    form.setFieldsValue({ destinationJob: undefined, destinationSubsegment: undefined });
    setSubsegmentList([]);
    setCandidateTransferModalVisibility(false);
  };
  const dropDownStatus = () => {
    return fetchJobsApiStatus === 'INPROGRESS' ? 'Loading...' : `No ${destinationName} found`;
  };
  const notFoundContent = dropDownStatus();

  const notFoundSubsegmentContent = fetchSubsegmentApiStatus === 'INPROGRESS' ? 'Loading...' : `No Sub-segments found`;

  const selectComponentWithForm = (
    <Form.Item rules={[{ required: true, message: 'Please enter Job' }]}>
      {form?.getFieldDecorator('destinationJob', {
        rules: [
          {
            validator: (rule, value, callback) =>
              validateCandidateTransfer(
                value,
                callback,
                candidateIds,
                isEnableCustomSelect,
                customSelectNumber,
                invalidCandidateCallbackMessage
              ),
          },
        ],
      })(
        <Select
          allowClear
          notFoundContent={notFoundContent}
          showSearch
          filterOption={false}
          showArrow={false}
          className={styles.select}
          dropdownStyle={{ zIndex: 25000 }}
          placeholder={selectPlaceholder}
          onSearch={value => onSearch(value, destinationName)}
          disabled={!destinationName}
          onSelect={onSelect}
          onChange={onDeselect}
          getPopupContainer={(destinationBox) => destinationBox.parentElement}
        >
          {options?.map(option => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );

  const selectSubsegmentComponentWithForm = (
    <Form.Item rules={[{ required: false }]}>
      {form?.getFieldDecorator('destinationSubsegment', {
        rules: [
          {
            validator: (rule, value, callback) =>
              validateCandidateTransfer(
                value,
                callback,
                candidateIds,
                isEnableCustomSelect,
                customSelectNumber,
                invalidCandidateCallbackMessage
              ),
          },
        ],
      })(
        <Select
          allowClear
          notFoundContent={notFoundSubsegmentContent}
          showSearch
          filterOption={false}
          showArrow={false}
          className={styles.select}
          dropdownStyle={{ zIndex: 25000 }}
          placeholder={selectSubsegmentPlaceholder}
          onSearch={value => onSubsegmentSearch(form.getFieldValue('destinationJob'), value)}
          disabled={!destinationName}
          getPopupContainer={(subSegmentSelectBox) => subSegmentSelectBox.parentElement}
        >
          {subsegmentList?.map(option => (
            <Option key={option.Id} value={option.Id}>
              {option.Name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );

  return (
    <EnhancedCandidateTransferModal
      selectComponentWithForm={selectComponentWithForm}
      tooltipTitle={tooltipTitle}
      onClickTransferIcon={onClickTransferIcon}
      candidateTransferIconClassName={candidateTransferIconClassName}
      isCandidateTransferModalVisible={isCandidateTransferModalVisible}
      setCandidateTransferModalVisibility={setCandidateTransferModalVisibility}
      handleOnClose={handleOnClose}
      handleBulkTranfer={handleBulkTranfer}
      actionName={actionName}
      candidateTranserApiStatus={candidateTranserApiStatus}
      setActionName={setActionName}
      setOptions={setOptions}
      form={form}
      setDestinationName={setDestinationName}
      destinationName={destinationName}
      transferType={transferType}
      fillColor={fillColor}
      candidateContext={candidateContext}
      isAryaRecommendedSelectAllChecked={isAryaRecommendedSelectAllChecked}
      customSelectNumber={customSelectNumber}
      jobId={jobId}
      selectSubsegmentComponentWithForm={selectSubsegmentComponentWithForm}
      activeTab={activeTab}
      selectedSubSegmentId={selectedSubSegmentId}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CandidateTransfer));
export { CandidateTransfer as CandidateTransferWithoutStore };
