import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Form, Timeline, Icon, Collapse, Select, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { getEmailTemplatesById, getCurrentEmailTemplateIds } from '../../Reducers/EmailTemplates';
import RichTextEditor from '../../Components/Editor/RichTextEditor';
import HtmlViewer from '../../Components/HtmlViewer/HtmlViewer';
import ErrorBoundary from '../../Components/ErrorBoundary/ErrorBoundary';
import BaseTemplateForm from '../../Components/Connect/BaseTemplateForm/BaseTemplateForm';
import createRequestBody from './DripRequestBodyCreator';
import { getRemovedFormFields } from '../../Utils/FormUtils.ts';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getFilteredMergeTags } from '../../Utils/MergeTagsUtils';
import { validateTemplate } from '../../Utils/Validators';
import * as ConnectActions from '../../Actions/ConnectActions';
import './DripsDisplayContainer.scss';
import eventTypes from '../../Analytics/EventTypes';
import message from '../CandidateDrawer/messages';

const { Panel } = Collapse;
const { Option } = Select;

const reactEmailEditor = 'reactemaileditor';

const mapStateToProps = state => ({
  emailTemplatesById: getEmailTemplatesById(state),
  currentEmailTemplateIds: getCurrentEmailTemplateIds(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
};

class DripsDisplayContainer extends BaseTemplateForm {
  constructor(props) {
    super(props);
    this.state = {
      drops: [{}],
      preHeaderVisibility: {},
    };

    this.addDrip = this.addDrip.bind(this);
    this.removeDrip = this.removeDrip.bind(this);
    this.getDripConditionItem = this.getDripConditionItem.bind(this);
    this.getPanelHeader = this.getPanelHeader.bind(this);
    this.getPanelBody = this.getPanelBody.bind(this);
    this.getTimelineItem = this.getTimelineItem.bind(this);
    this.getDripTimeline = this.getDripTimeline.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.onChangeDripSelection = this.onChangeDripSelection.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const propDrops = _.get(props, ['initialValues', 'Drops'], [{}]);
    if (!_.isEqual(_.get(props, ['initialValues', 'Drops'], [{}]), state.propDrops)) {
      return {
        ...state,
        drops: _.get(props, ['initialValues', 'Drops'], [{}]),
        propDrops,
      };
    }

    return {
      ...state,
      propDrops,
    };
  }

  addDrip() {
    const { drops } = this.state;
    this.setState({
      drops: [...drops, { TimeAfterPrevious: 1, TriggerOn: 'NotViewed' }],
    });
  }

  validateEmailTemplate = requestBody => {
    const dripsBody = requestBody?.Drops?.filter(x => x?.EditorUsed?.toLowerCase() !== reactEmailEditor)
      .map(drop => drop.Body)
      .filter(x => x);
    const dripsSubject = requestBody?.Drops?.map(drop => drop.Subject).filter(x => x);
    const { emailSubjectMergeTags: subjectMergeTags, emailBodyMergeTags: bodyMergeTags } = this.getMergeTags();
    const body = dripsBody?.join(',');
    const subject = dripsSubject?.join(',');
    return validateTemplate({ subject, body, subjectMergeTags, bodyMergeTags });
  };

  removeDrip(index) {
    const { drops, preHeaderVisibility } = this.state;
    const { form } = this.props;
    const formValues = form.getFieldsValue();
    const removedFields = getRemovedFormFields(formValues, index);
    const dropsCopy = [...drops];
    dropsCopy.splice(index, 1);
    const updatePreHeaderVisibility = { ...preHeaderVisibility };
    delete updatePreHeaderVisibility[`template-preHeader_${index}`];
    this.setState({
      drops: dropsCopy,
      preHeaderVisibility: updatePreHeaderVisibility,
    });
    const removedFormFieldsObject = {};
    removedFields.forEach(x => {
      removedFormFieldsObject[x] = undefined;
    });
    form.setFieldsValue(removedFormFieldsObject);
  }

  getDripConditionItem(index) {
    const { drops } = this.state;
    const { form, viewOnly, isCreateDripTemplate } = this.props;
    return (
      <div>
        {!isCreateDripTemplate ? _.get(drops, [index, 'Name']) : null}
        <div className="drip-condition connect-drip-condition">
          <div
            onClick={event => {
              event.stopPropagation();
            }}
            role="presentation"
          >
            {form.getFieldDecorator(`template-condition_${index}`, {
              initialValue: _.get(drops, [index, 'TriggerOn'], 'NotViewed'),
              rules: [{ required: true, message: 'Please select a condition!' }],
            })(
              <Select dropdownClassName="create-template" disabled={viewOnly} dropdownStyle={{ zIndex: 2401 }}>
                <Option value="NotViewed">
                  <FormattedMessage {...message.dripSendIfNotOpenLabel} />
                </Option>
                <Option value="ViewedNotClicked">
                  <FormattedMessage {...message.dripSendIfOpenLabel} />
                </Option>
              </Select>
            )}
          </div>
          <div
            onClick={event => {
              event.stopPropagation();
            }}
            role="presentation"
          >
            {form.getFieldDecorator(`template-days_${index}`, {
              initialValue: _.get(drops, [index, 'TimeAfterPrevious'], 1),
              rules: [{ required: true, message: 'Please enter the days!' }],
            })(<InputNumber min={1} disabled={viewOnly} />)}
          </div>
          <div className="">
            <FormattedMessage {...message.daysAfterPreviousEmailLabel} />
          </div>
        </div>
      </div>
    );
  }

  onTemplateSelect = (emailTemplateId, index) => {
    const { drops, preHeaderVisibility } = this.state;
    const { emailTemplatesById, form } = this.props;
    const clonedDrops = _.cloneDeep(drops);
    clonedDrops[index] = _.pick(emailTemplatesById[emailTemplateId], [
      'EmailTemplateId',
      'IsBotEnabled',
      'Body',
      'Design',
      'Subject',
      'PreHeader',
      'EditorUsed',
    ]);
    this.setState({
      drops: clonedDrops,
      preHeaderVisibility: {
        ...preHeaderVisibility,
        [`template-preHeader_${index}`]: clonedDrops[index]?.PreHeader ?? false,
      },
    });
    const templateSubjectField = `template-subject_${index}`;
    const templateBodyFiled = `template-body_${index}`;
    const templatePreHeaderField = `template-preHeader_${index}`;
    form.setFieldsValue({
      [templateSubjectField]: { htmlContent: clonedDrops[index].Subject },
      [templatePreHeaderField]: { htmlContent: clonedDrops[index]?.PreHeader },
      [templateBodyFiled]: { htmlContent: clonedDrops[index].Body },
    });
  };

  getPanelHeader(index) {
    const { drops } = this.state;
    const { form, currentEmailTemplateIds, emailTemplatesById, viewOnly, isCreateDripTemplate } = this.props;
    const selectedEmailTemplate = _.get(drops, [index, 'EmailTemplateId'], undefined);
    return (
      <div className="drip-condition-item">
        {isCreateDripTemplate ? (
          <div className="select-template-row">
            <Form.Item className="select-template">
              <div
                onClick={event => {
                  event.stopPropagation();
                }}
                role="presentation"
              >
                {form.getFieldDecorator(`template-id_${index}`, {
                  initialValue: selectedEmailTemplate,
                  rules: [{ required: true, message: 'Please select a template!' }],
                })(
                  <Select
                    dropdownClassName="create-template"
                    placeholder={<FormattedMessage {...message.chooseDripTemplateLabel} />}
                    disabled={viewOnly}
                    onChange={value => this.onTemplateSelect(value, index)}
                  >
                    {currentEmailTemplateIds.map(emailTemplateId => {
                      const emailTemplate = emailTemplatesById[emailTemplateId];
                      return (
                        <Option key={emailTemplateId} value={emailTemplateId}>
                          {emailTemplate.Name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </div>
            </Form.Item>
            {drops.length - 1 === index && index && !viewOnly ? (
              <Icon
                type="delete"
                style={{ fontSize: 24 }}
                onClick={() => {
                  this.removeDrip(index);
                }}
              />
            ) : null}
          </div>
        ) : null}

        {index || !isCreateDripTemplate ? (
          <Form.Item>{this.getDripConditionItem(index, selectedEmailTemplate)}</Form.Item>
        ) : null}
      </div>
    );
  }

  getMergeTags = () => {
    const { featureToggleList, mergeTags, version, jobDetails } = this.props;
    const filteredMergeTags = getFilteredMergeTags(
      mergeTags,
      {
        CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
        JobSummary: featureToggleList.JobSummary,
        CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
      },
      version,
      jobDetails
    );
    const emailSubjectMergeTags = filteredMergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'));
    const emailBodyMergeTags = filteredMergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailBody'));
    const emailPreHeaderMergeTags = filteredMergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));
    return { emailSubjectMergeTags, emailBodyMergeTags, emailPreHeaderMergeTags };
  };

  addPreHeaderToEmail = ({ templateIndex }) => {
    const { preHeaderVisibility } = this.state;
    const updatedPreHeaderVisbility = { ...preHeaderVisibility };
    updatedPreHeaderVisbility[`template-preHeader_${templateIndex}`] = true;
    this.setState({
      preHeaderVisibility: updatedPreHeaderVisbility,
    });
  };

  removePreHeaderFromEmail = ({ templateIndex }) => {
    const { form } = this.props;
    const { preHeaderVisibility } = this.state;
    const updatedPreHeaderVisbility = { ...preHeaderVisibility };
    updatedPreHeaderVisbility[`template-preHeader_${templateIndex}`] = false;
    this.setState({
      preHeaderVisibility: updatedPreHeaderVisbility,
    });
    form.setFieldsValue({
      [`template-preHeader_${templateIndex}`]: { htmlContent: undefined },
    });
  };

  validatePreHeader = (rule, value, callback) => {
    if (value?.plainTextContent?.length > 100) {
      callback('Preheader cannot exceed 100 characters');
    } else {
      callback();
    }
  };

  getPanelBody(index, drop = {}) {
    const { emailTemplatesById, form, viewOnly, scrollMailRef, isConnectMailWindow, isCreateDripTemplate } = this.props;

    const { emailSubjectMergeTags, emailBodyMergeTags } = this.getMergeTags();
    const initialValues = {
      Subject: _.get(emailTemplatesById, [form.getFieldValue(`template-id_${index}`), 'Subject'], undefined),
      PreHeader: _.get(emailTemplatesById, [form.getFieldValue(`template-id_${index}`), 'PreHeader'], undefined),
      IsBotEnabled: _.get(emailTemplatesById, [form.getFieldValue(`template-id_${index}`), 'IsBotEnabled'], undefined),
      Body: _.get(emailTemplatesById, [form.getFieldValue(`template-id_${index}`), 'Body'], undefined),
      IsReadonly: _.get(emailTemplatesById, [drop.EmailTemplateId, 'IsReadonly']),
      ...drop,
    };

    const emailBodyContainerClassname = 'candidate-360-drip-view';
    const emaiSubjectContainerId = 'candidate-360-subject-drip-view';

    return (
      <div
        className={classNames({
          'drip-display-container-candidate-360-mail': isConnectMailWindow,
        })}
      >
        <div className="row-two  emailsubject " id={emaiSubjectContainerId}>
          <ErrorBoundary>
            {form.getFieldDecorator(`template-subject_${index}`, {
              rules: [{ required: true, message: 'Please input the template subject' }],
              initialValue: { htmlContent: initialValues.Subject },
              valuePropName: 'editorContent',
            })(
              <RichTextEditor
                showToolbar={!viewOnly}
                mergeTags={emailSubjectMergeTags}
                onlyMergeTags
                disabled={viewOnly}
                isDripEmail
                showSubjectLabel
              />
            )}
          </ErrorBoundary>
        </div>
        <ErrorBoundary>
          <div className={emailBodyContainerClassname}>
            <p>
              <FormattedMessage {...message.bodyLabel} />
            </p>
            {form.getFieldDecorator(`template-body_${index}`, {
              rules: [{ required: true, message: 'Please input the template body' }],
              initialValue: { htmlContent: initialValues.Body },
              valuePropName: 'editorContent',
            })(
              drop.EditorUsed?.toLowerCase() === reactEmailEditor ? (
                <HtmlViewer htmlContent={initialValues.Body} className="drip-html-viewer" />
              ) : (
                <RichTextEditor
                  showToolbar={!viewOnly}
                  mergeTags={emailBodyMergeTags}
                  disabled={initialValues.IsReadonly || viewOnly}
                  mailRef={scrollMailRef}
                  isCreateDripTemplate={isCreateDripTemplate}
                  isEmailPanel={isConnectMailWindow}
                />
              )
            )}
          </div>
        </ErrorBoundary>
      </div>
    );
  }

  getTimelineItem(index, drop = {}) {
    const { isCreateDripTemplate, initialTimelineIndex } = this.props;
    const dot = <div style={{ fontSize: 14 }}>{initialTimelineIndex + index + 1}</div>;
    return (
      <Timeline.Item dot={dot} key={index}>
        <Collapse onChange={() => this.onChangeDripSelection(index)}>
          <Panel showArrow={!isCreateDripTemplate} header={this.getPanelHeader(index)}>
            {this.getPanelBody(index, drop)}
          </Panel>
        </Collapse>
      </Timeline.Item>
    );
  }

  getDripTimeline() {
    const { drops } = this.state;
    const { viewOnly, isCreateDripTemplate } = this.props;
    const pendingDot =
      isCreateDripTemplate && !viewOnly ? (
        <Icon type="plus-circle" onClick={this.addDrip} style={{ fontSize: 24 }} />
      ) : null;
    return (
      <Timeline pending={isCreateDripTemplate && !viewOnly} pendingDot={pendingDot}>
        {drops.length ? drops.map((drop, index) => this.getTimelineItem(index, drop)) : this.getTimelineItem(0)}
      </Timeline>
    );
  }

  onChangeDripSelection(index) {
    const { preHeaderVisibility, drops } = this.state;
    const updatedPreHeaderVisibility = { ...preHeaderVisibility };
    updatedPreHeaderVisibility[`template-preHeader_${index}`] = drops[index]?.PreHeader ?? false;
    this.setState({
      selectedDripIndex: index,
      preHeaderVisibility: updatedPreHeaderVisibility,
    });
  }

  saveTemplate(event) {
    const { form, onSubmit, initialValues, setInvalidTemplateNotification } = this.props;
    const { drops } = this.state;
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const requestBody = createRequestBody(values, drops, 0, initialValues);
        if (!this.validateEmailTemplate(requestBody)) {
          setInvalidTemplateNotification();
          return;
        }
        onSubmit(requestBody);
        form.resetFields();
      }
    });
  }

  render() {
    const { onClose, viewOnly, initialValues, isCreateDripTemplate, timeLineClassName } = this.props;
    return (
      <Form onSubmit={this.saveTemplate}>
        {isCreateDripTemplate ? (
          <div className="drip-template-name">
            {this.getTemplateNameItem('Drip Template Name', 'Please input the template name', initialValues.Name)}
          </div>
        ) : null}
        <div className={`${timeLineClassName} drips-display-time-line-item`}>{this.getDripTimeline()}</div>
        {isCreateDripTemplate ? (
          <div className="footer">
            {!viewOnly
              ? this.getSaveButton(
                  <FormattedMessage {...message.saveTemplateLabel} />,
                  null,
                  eventTypes.connect.dripTemplates.save
                )
              : null}
            {this.getCancelButton(
              <FormattedMessage {...message.cancelButton} />,
              onClose,
              eventTypes.connect.dripTemplates.cancel
            )}
          </div>
        ) : null}
      </Form>
    );
  }
}

DripsDisplayContainer.propTypes = {
  initialValues: PropTypes.shape({}),
};

DripsDisplayContainer.defaultProps = {
  initialValues: {},
  initialTimelineIndex: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(DripsDisplayContainer);
export { DripsDisplayContainer as DripsDisplayContainerWithoutStore };
