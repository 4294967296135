import React from 'react';
import { Dropdown, Menu, Input, Icon, Empty, Tooltip, Form } from 'antd';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { useKeyboardListNavigation } from 'use-keyboard-list-navigation';
import styles from './AddSuggestedTag.module.scss';
import SpinLoader from '../../Utils/SpinLoader';
import useClickOutside from '../../Hooks/useClickOutside';
import { validateTagInput } from '../../Utils/FormValidators';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function AddSuggestedTag(props) {
  const {
    suggestedTags = [],
    onTagAdd,
    onTagChange,
    totalSuggestedTagsCount,
    zIndex,
    suggestedTagsApiStatus,
    setNotification,
    tagsResult,
    form,
    isInvalidTagErrorVisible,
    setInvalidTagErrorVisiblity,
    intl,
  } = props;

  const [searchTerm, setSearchTerm] = React.useState(undefined);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const dropdownRef = React.useRef(null);

  const { getFieldDecorator, setFieldsValue } = form;

  const keyBoardData = useKeyboardListNavigation({
    list: [{ Id: -1 }, ...suggestedTags],
    onEnter: () => {},
  });
  const { selected } = keyBoardData;

  React.useEffect(() => {
    setSelectedOption(selected);
    if (document.getElementById(`id-${selected?.Id}`))
      document.getElementById(`id-${selected?.Id}`).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [selected.Id]);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (isInvalidTagErrorVisible) setTimeout(dropdownRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
  }, [isInvalidTagErrorVisible]);

  const clearField = () => {
    setSearchTerm(undefined);
    setSelectedOption(null);
    setFieldsValue({
      tagInputField: undefined,
    });
  };

  const onInputChange = e => {
    onTagChange(e.target.value);
    setSearchTerm(e.target.value);
  };

  const onTagSelect = option => {
    if (tagsResult?.length > 9) {
      setNotification('ERROR', 'Number of tags cannot be greater than 10');
    } else if (option?.Id === -1 || !option) {
      if (searchTerm?.trim()?.length > 0 && searchTerm?.trim().length < 50) {
        onTagAdd(searchTerm?.trim());
      } else if (searchTerm?.trim()?.length === 0) {
        setNotification('ERROR', 'Please enter tag');
      } else if (searchTerm?.trim().length > 50) {
        setNotification('ERROR', 'A Tag cannot be more than 50 characters');
      }
    } else if (option?.Id !== -1) {
      onTagAdd(option?.Name);
    }
    clearField();
  };

  const onTagItemClick = tag => {
    onTagSelect(tag);
    clearField();
  };

  const handleOutsideClick = async () => {
    setTimeout(clearField, 300);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const dropdownValue = selected;
      if (dropdownValue !== null || dropdownValue !== {}) {
        e.preventDefault();
      }
      onTagSelect(selectedOption);
      clearField();
    }
  };

  const handleMenuItemMouseOver = (isMouseOver, option) => {
    if (isMouseOver) {
      setSelectedOption(option);
      const index = suggestedTags.findIndex(x => x.Id === option.Id);
      keyBoardData.set({ cursor: index + 1 });
    }
  };
  const noContentDropdownClassName = totalSuggestedTagsCount === 0 ? styles.noContentDropdown : '';
  useClickOutside(dropdownRef, handleOutsideClick);

  const isEmptyIconVisible =
    (totalSuggestedTagsCount === 0 || suggestedTagsApiStatus === 'FAILED') && suggestedTagsApiStatus !== 'INPROGRESS';
  const isSpinLoaderVisible = totalSuggestedTagsCount === undefined || suggestedTagsApiStatus === 'INPROGRESS';

  const isEnterIconVisible = totalSuggestedTagsCount !== 0 || suggestedTagsApiStatus !== 'FAILED';

  const getEnterIcon = () => {
    return isEnterIconVisible ? (
      <div
        onClick={() => onTagItemClick({ Id: -1 })}
        onMouseEnter={() => handleMenuItemMouseOver(true, { Id: -1 })}
        role="presentation"
        className={classNames(styles.menuItem, {
          [styles.liSelected]: selectedOption ? selectedOption.Id === -1 : true,
        })}
        id="id--1"
      >
        <Icon type="enter" />
        &nbsp;<span className={styles.newTag}>to add tag</span>
      </div>
    ) : null;
  };

  const getSpinLoader = () => {
    return isSpinLoaderVisible ? <SpinLoader text="Fetching tags..." /> : null;
  };

  const getEmptyIcon = () => {
    return isEmptyIconVisible ? (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 20,
          marginBottom: 0,
        }}
      />
    ) : null;
  };

  const getSuggestedTags = () => {
    return totalSuggestedTagsCount > 0
      ? suggestedTags.map(option => {
          return (
            <Tooltip title={option.Name} key={option.Id}>
              <div
                key={option.Id}
                onClick={() => onTagItemClick(option)}
                onMouseEnter={() => handleMenuItemMouseOver(true, option)}
                role="presentation"
                className={classNames(styles.menuItem, {
                  [styles.liSelected]: selectedOption ? selectedOption.Id === option.Id : false,
                })}
                id={`id-${option.Id}`}
              >
                {option.Name}
              </div>
            </Tooltip>
          );
        })
      : null;
  };

  const menu = (
    <Menu>
      <div>
        {getEnterIcon()}
        <Menu.Divider />
        <div className={`${styles.dropdown} ${noContentDropdownClassName}`}>
          {getSpinLoader()}
          {getEmptyIcon()}
          {getSuggestedTags()}
        </div>
      </div>
    </Menu>
  );

  const isDropdownMenuVisible = searchTerm?.trim()?.length && !isInvalidTagErrorVisible;
  const dropdownMenu = isDropdownMenuVisible ? menu : <div />;
  const isDropdownVisible = searchTerm?.trim()?.length > 0;

  return (
    <div role="button" tabIndex={0} onKeyDown={handleKeyDown} ref={dropdownRef} className={styles.dropdownContainer}>
      <Dropdown
        overlay={dropdownMenu}
        visible={isDropdownVisible}
        zIndex={zIndex}
        overlayClassName={styles.dropdownOverlay}
      >
        <Form.Item className={styles.tagInputForm}>
          {getFieldDecorator('tagInputField', {
            rules: [
              {
                required: true,
                validator: (rule, value, callback) =>
                  validateTagInput(rule, value, callback, setInvalidTagErrorVisiblity),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ ...message.addTagLabel })}
              onChange={onInputChange}
              value={searchTerm}
              size="small"
              style={{ width: '70px', borderRadius: '20px', fontSize: '12px', borderStyle: 'dashed' }}
            />
          )}
        </Form.Item>
      </Dropdown>
    </div>
  );
}

export default (injectIntl(Form.create()(AddSuggestedTag)));
export { AddSuggestedTag as AddSuggestedTagWithoutForm };
