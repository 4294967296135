import React from 'react';
import { Anchor, Form } from 'antd';
import { injectIntl } from 'react-intl';
import styles from './AnchorScroll.module.scss';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

function AnchorScroll(props) {
  const { scrollingContainer, anchorMenuList, preventUrlChange, anchorTargetOffset, defaultActiveLink, intl } = props;

  const onClickAnchorItem = event => {
    if (preventUrlChange) event.preventDefault();
  };

  const getAnchorContainer = () => scrollingContainer || window;
  const newAnchorMenuList = anchorMenuList.map(({ id, name }) => {
    const noSpaces = name.replace(/[\s/]+/g, '');
    const lowerCase = noSpaces.charAt(0).toLowerCase() + noSpaces.slice(1);
    const Name = `${lowerCase}Label`;
    return {
      id,
      name: Name,
    };
  });
  return (
    <div className={styles.anchorScrollWrapper}>
      <Anchor
        className={styles.anchorList}
        getContainer={getAnchorContainer}
        targetOffset={anchorTargetOffset}
        showInkInFixed
        onClick={onClickAnchorItem}
        activeLink={defaultActiveLink}
      >
        {newAnchorMenuList.map(({ id, name }) => (
          <Anchor.Link
            href={`#${id}`}
            title={intl.formatMessage({ ...message[name] })}
            className={styles.anchorItemItem}
            data-testid={`anchor-link-${id}`}
          />
        ))}
      </Anchor>
    </div>
  );
}

export default injectIntl(Form.create()(AnchorScroll));
export {AnchorScroll as AnchorScrollWithoutInjectIntl};
