import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Icon, Tooltip } from 'antd';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PreHeaderIcon, SourceHtmlIcon } from '../../../../Icons/AryaIcons';
import { generateMergeTags } from '../../../../Utils/EditorUtils';
import RichTextEditor from '../../../Editor/RichTextEditor';
import HtmlViewer from '../../../HtmlViewer/HtmlViewer';
import ReactEmailEditor from '../../../ReactEmailEditor/ReactEmailEditor';
import CKEditor from '../../../NewEditor/RichTextEditor';
import './CreateEmailTemplate.scss';
import { getPreHeaderValidationStatus } from '../../../../Utils/EmailUtils/EmailUtils';
import { convertHyperlinkToPlainText } from '../../../../Utils/TextUtils';
import message from '../../../../Containers/Connect/ConnectMessages';

const EmailSubjectAndBodyWrapper = React.forwardRef((props, ref) => {
  const {
    viewOnly,
    emailSubjectMergeTags,
    addEmailSubjectMergeTags,
    subject,
    updateSubjectContent,
    isAdvancedTemplate,
    isEditTemplateDrawer,
    isReactEmailEditorEnabled,
    toogleAdvancedEditor,
    onClearAdvancedHtmlContent,
    systemEmailTemplate,
    mergeTags,
    isReadonly,
    getEditorUsed,
    body,
    design,
    updateAdvancedHtmlContent,
    updateCKeditorBodyContent,
    updateBodyContent,
    setHtmlToolActiveStatus,
    addEmailPreHeaderMergeTags,
    updatePreHeaderContent,
    preHeader,
    emailPreHeaderMergeTags,
    preHeaderVisibility,
    addPreHeaderToEmail,
    removePreHeaderFromEmail,
    isWorkflow,
    intl,
  } = props;
  const { myRef, editorRef, preHeaderRef } = ref;

  const preHeaderValidationMessage = getPreHeaderValidationStatus(preHeaderRef) ? (
    <FormattedMessage {...message.preheaderCannotExceedCharacters} />
  ) : (
    ''
  );

  const getEditorHeader = () => {
    const advancedEmailEditorHeaderButtons = (
      <div>
        {!isEditTemplateDrawer ? (
          <span onClick={toogleAdvancedEditor} role="presentation" style={{ marginRight: '10px', cursor: 'pointer' }}>
            <FormattedMessage {...message.backToBasicEditorLabel} />
          </span>
        ) : null}
        {!viewOnly ? (
          <span
            style={{ marginRight: '10px', cursor: 'pointer' }}
            role="presentation"
            onClick={onClearAdvancedHtmlContent}
          >
            <FormattedMessage {...message.clearLabel} />
          </span>
        ) : null}
      </div>
    );
    const basicEmailEditorHeaderButtons = !isEditTemplateDrawer ? (
      <div onClick={toogleAdvancedEditor} style={{ cursor: 'pointer' }} role="presentation">
        <SourceHtmlIcon /> <FormattedMessage {...message.advancedEditorLabel} />
      </div>
    ) : null;
    const editorHeaderButtons = isAdvancedTemplate ? advancedEmailEditorHeaderButtons : basicEmailEditorHeaderButtons;

    return (
      <div className="email-template-description-tag">
        <FormattedMessage {...message.bodyCopyLabel} />{' '}
        <div>{isReactEmailEditorEnabled ? editorHeaderButtons : null}</div>
      </div>
    );
  };

  const getEditor = () => {
    const mergeTagScope = systemEmailTemplate ? 'WhiteGlove' : 'EmailBody';
    const emailBodyMergeTags = mergeTags?.filter(mergeTag => mergeTag.Scopes.includes(mergeTagScope));
    const editorUsed = getEditorUsed().toLowerCase();

    if (editorUsed === 'reactemaileditor') {
      if (!isReactEmailEditorEnabled || viewOnly)
        return (
          <div className="email-template-preview">
            <HtmlViewer htmlContent={body} />
          </div>
        );
      return (
        <ReactEmailEditor
          updateAdvancedHtmlContent={updateAdvancedHtmlContent}
          editorRef={editorRef}
          currentTemplateDesign={design}
          mergeTags={generateMergeTags(emailBodyMergeTags)}
        />
      );
    }

    if (editorUsed === 'ckeditor')
      return <CKEditor content={body} onChange={updateCKeditorBodyContent} mergeTags={emailBodyMergeTags} />;

    return (
      <RichTextEditor
        editorContent={{ htmlContent: body }}
        onChange={updateBodyContent}
        showAdditionalOptions
        mergeTags={emailBodyMergeTags}
        showToolbar={!(viewOnly || isReadonly)}
        disabled={viewOnly || isReadonly}
        sourceCodeSupport={false}
        setHtmlToolActiveStatus={setHtmlToolActiveStatus}
        isEmailTemplateCreate
      />
    );
  };

  const preHeaderMergeTags =
    !viewOnly && emailPreHeaderMergeTags?.length > 0 ? <span>{addEmailPreHeaderMergeTags}</span> : null;

  const preheaderExcludingHyperlink = isWorkflow ? preHeader : convertHyperlinkToPlainText(preHeader);

  const preHeaderButton = (
    <Tooltip zIndex={2223} title={<FormattedMessage {...message.preheaderLabel} />}>
      <Button id="workflowEmailAddPreHeaderButton" type="link" onClick={addPreHeaderToEmail}>
        <PreHeaderIcon />
      </Button>
    </Tooltip>
  );

  const displayPreHeader = preHeaderVisibility ? (
    <>
      <div className="subject-merge-tag-wrapper" id="preheader-container-wrapper">
        Preheader &nbsp;
        <span id="workflowPreHeaderTooltipIcon">
          <Tooltip title={<FormattedMessage {...message.shortSummaryTextLabel} />}>
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
        {preHeaderMergeTags}
      </div>
      <div className="workflowEmailPreHeaderContainer">
        <Form.Item help={preHeaderValidationMessage} validateStatus={getPreHeaderValidationStatus(preHeaderRef)}>
          <RichTextEditor
            className="email-pre-header"
            editorContent={{ htmlContent: preheaderExcludingHyperlink }}
            onChange={updatePreHeaderContent}
            mergeTags={emailPreHeaderMergeTags}
            showToolbar={false}
            ref={preHeaderRef}
            placeholder="Add Preheader"
            disabled={viewOnly}
          />
          <div className="removeIconSectionConnectEmail">
            <span
              id={classNames(
                { workflowEmailPreHeaderRemoveInAdvanceEditor: isAdvancedTemplate && isWorkflow },
                { workflowEmailPreHeaderRemoveInEditor: !isAdvancedTemplate && isWorkflow },
                { connectEmailPreHeaderRemoveInAdvanceEditor: isAdvancedTemplate && !isWorkflow },
                { connectEmailPreHeaderRemoveInEditor: !isAdvancedTemplate && !isWorkflow }
              )}
            >
              <Icon id="workflowEmailPreHeaderRemoveIcon" onClick={removePreHeaderFromEmail} type="delete" />
            </span>
          </div>
        </Form.Item>
      </div>
    </>
  ) : (
    preHeaderButton
  );

  const subjectExcludingHyperlink = isWorkflow ? subject : convertHyperlinkToPlainText(subject);

  return (
    <div>
      <div className="row-two">
        <div className="subject-merge-tag-wrapper">
          <FormattedMessage {...message.subjectLineLabel} />{' '}
          {!viewOnly && emailSubjectMergeTags?.length > 0 ? <span>{addEmailSubjectMergeTags}</span> : null}
        </div>
        <RichTextEditor
          className="mail-subject-editor emailsubject"
          editorContent={{ htmlContent: subjectExcludingHyperlink }}
          onChange={updateSubjectContent}
          mergeTags={emailSubjectMergeTags}
          showToolbar={false}
          ref={myRef}
          placeholder={intl.formatMessage({ ...message.subjectLineLabel })}
          disabled={viewOnly}
        />
        {displayPreHeader}
        &nbsp;
      </div>
      <div className={classNames('email-template-editor', 'candidate-360-view')}>
        {getEditorHeader()}
        {getEditor()}
      </div>
    </div>
  );
});

export default EmailSubjectAndBodyWrapper;
