import { Tabs } from 'antd';
import queryString from 'query-string';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  createSubSegment,
  fetchSubSegments,
  setSelectedSubsegment,
  setSubsegmentAddModalStatus,
  setSubsegmentCreateModalStatus,
  setSubsegmentPaginationData
} from '../../../Actions/SubSegmentActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getSourcingStats } from '../../../Reducers/JobReducer';
import {
  getAddModalVisbilityStatus,
  getCreateModalVisbilityStatus,
  getSubSegmentListofSegment,
  getSubsegmentPaginationData
} from '../../../Reducers/SubsegmentReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { getActualSourcingStats } from '../../../Utils/JobSourcingStatsUtil';
import message from '../../ManualSearchV2/ManualSearchMessages';
import SubsegmentModal from '../../SubSegment/SubsegmentModal';

const { TabPane } = Tabs;

const mapStateToProps = (state, props) => ({
  sourcingStats: getSourcingStats(state, props.jobId),
  subsegmentCreateModalStatus: getCreateModalVisbilityStatus(state),
  currentUser: getCurrentUser(state),
  createSubsegmentApiStatus: getApiStatus(state, 'createSubsegmentApiStatus'),
  addSubsegmentModalStatus: getAddModalVisbilityStatus(state),
  subsegmentPaginationData: getSubsegmentPaginationData(state),
  subsegmentList: getSubSegmentListofSegment(state, props.jobId),
});

const mapDispatchToProps = {
  setSubsegmentModalStatus: setSubsegmentCreateModalStatus,
  createSubSegmentAction: createSubSegment,
  fetchSubSegmentsAction: fetchSubSegments,
  setSubsegmentPagination: setSubsegmentPaginationData,
  setAddSubsegmentModalStatus: setSubsegmentAddModalStatus,
  setSelectedSubsegmentAction: setSelectedSubsegment,
};

export function CandidateStatusTab(props) {
  const {
    onStatusChange,
    activeTab,
    setStatus,
    setConnectionStatus,
    setPublishedStatus,
    setJobCandidateTabActiveTab,
    setUrl,
    jobId,
    sourcingStats,
    isCandidateCRMEnabled,
    changeTabStatus,
    setSubsegmentModalStatus,
    createSubSegmentAction,
    subsegmentCreateModalStatus,
    createSubsegmentApiStatus,
    fetchSubSegmentsAction,
    setAddSubsegmentModalStatus,
    currentUser,
    subsegmentPaginationData,
    setSubsegmentPagination,
    addSubsegmentModalStatus,
    location,
    setSelectedSubsegmentAction
  } = props;

  const onTabChange = activeKey => {
    if (!changeTabStatus) {
      switch (activeKey) {
        case 'sourced':
          setStatus('Sourced');
          setConnectionStatus(null);
          setPublishedStatus('Sourced');
          onStatusChange(activeKey);
          break;
        case 'shortlisted':
          setStatus('Shortlisted');
          setConnectionStatus(null);
          setPublishedStatus('Shortlisted');
          onStatusChange(activeKey);
          break;
        case 'subsegment':
          setStatus('Shortlisted');
          setConnectionStatus(null);
          setPublishedStatus('Shortlisted');
          onStatusChange(activeKey);
          break;
        case 'rejected':
          setStatus('Rejected');
          setConnectionStatus(null);
          setPublishedStatus('Rejected');
          onStatusChange(activeKey);
          break;
        case 'notes':
          setJobCandidateTabActiveTab({ activeTab: 'notes' });
          setUrl(`/segments/${jobId}/notes`);
          setStatus('notes');
          break;
        default:
          break;
      }
    } else {
      switch (activeKey) {
        case 'notes':
          setJobCandidateTabActiveTab({ activeTab: 'notes' });
          changeTabStatus(activeKey);
          break;
        default:
          changeTabStatus(activeKey);
          onStatusChange(activeKey);
          break;
      }
    }
  };


  const redirectToSubSegmentTab = async (subsegmentId) => {
    await setSelectedSubsegmentAction({ Id: subsegmentId })
    await setStatus('Shortlisted');
    setConnectionStatus(null);
    setPublishedStatus('Shortlisted');
    await onStatusChange('subsegment', null, null, true);
  }
  const queryParams = queryString.parse(location.search);
  const { subsegmentId } = queryParams;

  React.useEffect(() => {
    if (subsegmentId) {
      redirectToSubSegmentTab(subsegmentId)
    }
  }, []);

  const { shortlistedCount, exactRejectedCount } = getActualSourcingStats(sourcingStats);

  return isCandidateCRMEnabled ? (
    <div>
      <Tabs activeKey={activeTab} tabBarStyle={{ marginBottom: '24px' }} onChange={onTabChange}>
        <TabPane tab={<FormattedMessage {...message.searchLabel} />} key="sourced" />
        <TabPane
          tab={
            <>
              <FormattedMessage {...message.includedCandidatesLabel} />
              {` (${shortlistedCount})`}
            </>
          }
          key="shortlisted"
        />
        <TabPane tab={<FormattedMessage {...message.subSegmentsLabel} />} key="subsegment" />
        <TabPane
          tab={
            <>
              <FormattedMessage {...message.removedCandidatesLabel} />
              {` (${exactRejectedCount})`}
            </>
          }
          key="rejected"
        />
        <TabPane tab={<FormattedMessage {...message.notesLabel} />} key="notes" />
      </Tabs>
      <div>
        <SubsegmentModal
          activeTab={activeTab}
          isModalVisible={subsegmentCreateModalStatus}
          setIsModalVisible={setSubsegmentModalStatus}
          createSubSegmentAction={createSubSegmentAction}
          addSubsegmentModalStatus={addSubsegmentModalStatus}
          segmentId={jobId}
          currentUser={currentUser}
          fetchSubSegments={fetchSubSegmentsAction}
          setSubsegmentPaginationData={setSubsegmentPagination}
          subsegmentPaginationData={subsegmentPaginationData}
          setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
          createSubsegmentApiStatus={createSubsegmentApiStatus}
        />
      </div>
    </div>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateStatusTab);
export { CandidateStatusTab as CandidateStatusTabWithoutStore };
