import { defineMessages } from 'react-intl';

const message = defineMessages({
  aryaSourcing: {
    id: 'AryaActivation.Modal.Title.aryaSourcing',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Select Candidates you want to score and rank?',
  },
  activateArya: {
    id: 'AryaActivation.Modal.Footer.activateArya',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Activate Arya',
  },
  deactivateArya: {
    id: 'AryaActivation.Modal.Footer.deactivateArya',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Deactivate Arya',
  },
  save: {
    id: 'AryaActivation.Modal.Footer.save',
    defaultMessage: 'Save',
  },
  chooseSource: {
    id: 'AryaActivation.Modal.Body.chooseSource',
    defaultMessage: 'Choose Source',
  },
  aryaScoringRanking: {
    id: 'AryaScoringRanking.Modal.Body.checkboxTopMessage',
    defaultMessage: `Scored and Ranked Candidates`,
  },
  aryaScoringRankingApplied: {
    id: 'AryaScoringRanking.Modal.Body.checkboxBottomMessage',
    defaultMessage: `Applied Candidates`,
  },
  persistCandidatesTypeQuestionMessage: {
    id: 'AryaScoringRanking.Modal.Body.persistCandidateTypeQuestionMessage',
    defaultMessage: 'Select the candidates that you want to keep'
  },
  warningMessage: {
    id: 'AryaScoringRanking.Modal.Body.warningMessage',
    defaultMessage: 'All scored and ranked candidates will be deleted'
  }
});

export default message;
