import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form } from 'antd';
import MinMaxExperience from '../../../../Components/MinMaxExperience/MinMaxExperience';
import styles from './ExperienceSection.module.scss';
import message from '../../ManualSearchMessages';

function ExperienceSection(props) {
  const { form, defaultFormValues, intl } = props;
  return (
    <div className={styles.experienceSection}>
      <div className={styles.experienceLabel}>
        <FormattedMessage {...message.experienceRangeHeader} />
      </div>
      <div className={styles.experienceRangeWrapper}>
        <MinMaxExperience
          form={form}
          initialMinExperience={defaultFormValues?.Experience?.MinExperience || undefined}
          initialMaxExperience={defaultFormValues?.Experience?.MaxExperience || undefined}
          showLabel={false}
          inputClassName={styles.minMaxInput}
          minExperiencePlaceholder={intl.formatMessage({ ...message.fromLabel })}
          maxExperiencePlaceholder={intl.formatMessage({ ...message.toLabel })}
        />
      </div>
    </div>
  );
}

export default injectIntl(Form.create()(ExperienceSection));
