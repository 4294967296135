import React from 'react';
import { Input,Form } from 'antd';
import { injectIntl } from 'react-intl';
import message from '../../ManualSearchV2/ManualSearchMessages';

const { Search } = Input;

function CandidateSearchFilter(props) {
  let onChangeTimeoutReference;
  const { applyCandidateFilter, searchTerm, setIsFilteredCandidatesCountVisible, onChange,intl } = props;

  const onSearchFilter = value => {
    if (value) {
      applyCandidateFilter({ modifiedFilters: { SearchQuery: searchTerm ?? '' }, isFilterModifed: true });
    }
    setIsFilteredCandidatesCountVisible(searchTerm !== '');
  };

  const onSearchChange = event => {
    if (!event.target.value) {
      onChangeTimeoutReference = setTimeout(() => {
        applyCandidateFilter({ modifiedFilters: { SearchQuery: '' } });
      }, 800);
      setIsFilteredCandidatesCountVisible(false);
    } else {
      clearTimeout(onChangeTimeoutReference);
    }
    onChange(event);
  };

  return (
    <Search
      className="candidate-search-box"
      placeholder={intl.formatMessage({ ...message.searchButton })}
      size="default"
      allowClear
      onSearch={onSearchFilter}
      value={searchTerm}
      onChange={onSearchChange}
    />
  );
}

export default (injectIntl(Form.create()(CandidateSearchFilter)));
export { CandidateSearchFilter as CandidateSearchFilterWithoutInjectIntl };
