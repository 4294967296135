import { Form, Select, Tooltip, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { QuickSearchEducationOptions } from '../../Components/QuickSearchForm/QuickSearchFormUtils';
import EducationContext from './EducationContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const { Item } = Form;

export function getSupportedSourcesDisplayText(sourceNames) {
  const commonText = 'Supported by';
  let displayText;
  let sourcesNamesToBeShown;
  let sourceNamesToBeShownInTooltip;
  switch (sourceNames.length) {
    case 0:
      displayText = null;
      break;
    case 1:
      displayText = `${commonText} ${sourceNames[0]}`;
      break;
    case 2:
      displayText = `${commonText} ${sourceNames[0]} and ${sourceNames[1]}`;
      break;
    case 3:
      displayText = `${commonText} ${sourceNames[0]}, ${sourceNames[1]} and ${sourceNames[2]}`;
      break;
    default:
      sourcesNamesToBeShown = `${sourceNames[0]}, ${sourceNames[1]} and ${sourceNames[2]}`;
      sourceNamesToBeShownInTooltip = sourceNames.slice(3);
      displayText = (
        <div>
          {`${commonText} ${sourcesNamesToBeShown} `}
          <Tooltip
            title={sourceNamesToBeShownInTooltip?.join(', ')}
          >{`+ ${sourceNamesToBeShownInTooltip.length}`}</Tooltip>
        </div>
      );
  }
  return displayText;
}

export default function Degree(props) {
  const { initialValue } = props;
  const { form } = React.useContext(EducationContext);
  return (
    <Item colon={false}>
      {form.getFieldDecorator('Degree', {
        initialValue,
      })(
        <Select mode="multiple" style={{ width: '100%' }} placeholder={<FormattedMessage {...message.selectEducationLabel} />} showArrow>
          {QuickSearchEducationOptions.map(option => (
            <Select.Option key={option.value} value={option.value}>
              <span>{option.displayText}</span>
              <br />
              <Typography.Text type="secondary">{getSupportedSourcesDisplayText(option.supportedBy)}</Typography.Text>
            </Select.Option>
          ))}
        </Select>
      )}
    </Item>
  );
}
