import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Pagination, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import appConfig from '../../Config/Config';
import styles from './CandidateListPagination.module.scss';
import messages from '../Placeholders/PlaceholdersMessages';

export function itemRender({
  showPreviousNextButton,
  type,
  originalElement,
  isNextButtonDisabled,
  currentPage,
  pageSize,
  totalCandidate,
}) {
  if (type === 'prev' && currentPage === 1) {
    return (
      <span className={styles.prevNextButtonCommonStyle}>
        <FormattedMessage {...messages.previousLabel} />
      </span>
    );
  }

  if (type === 'next' && currentPage * pageSize >= totalCandidate) {
    return (
      <span className={styles.prevNextButtonCommonStyle}>
        <FormattedMessage {...messages.nextLabel} />
      </span>
    );
  }

  if (type === 'next' && isNextButtonDisabled) {
    return (
      <span style={{ color: 'rgba(0, 0, 0, 0.25)', margin: '0px 10px', cursor: 'not-allowed', opacity: 0.5 }}>
        <FormattedMessage {...messages.nextDisabledLabel} />
      </span>
    );
  }

  if (showPreviousNextButton && type === 'prev') {
    return (
      <span style={{ color: '#13c26b', margin: '0px 10px' }}>
        <FormattedMessage {...messages.previousLabel} />
      </span>
    );
  }
  if (showPreviousNextButton && type === 'next') {
    return (
      <span style={{ color: '#13c26b', margin: '0px 10px' }}>
        <FormattedMessage {...messages.nextLabel} />
      </span>
    );
  }
  return originalElement;
}

function CandidateListPagination(props) {
  const {
    pageSize,
    currentPage,
    onChange,
    disabled,
    totalCandidate,
    totalCandidatesDisplayCount,
    onShowSizeChangeCallBack,
    inlineStyles,
    showPreviousNextButton = true,
    onlyPrevNext,
    showRange = true,
    previousSuccessfulCandidateFetchedPage,
    pageSizeOptions,
    paginationId,
    candidateContext,
    status,
    shouldRemovePagination,
    isNextButtonDisabled,
    isExactMatchSearch
  } = props;
  const currentUser = useSelector(state => getCurrentUser(state));

  const onShowSizeChange = (page, size) => {
    const currentUserEmail = currentUser?.email;
    const localStorageKey = `CandidateListPageSize:${paginationId}`;
    const candidateListPageSizeConfig = localStorage.getItem(localStorageKey);
    const newCandidateListPageSizeConfig =
      (candidateListPageSizeConfig && JSON.parse(candidateListPageSizeConfig)) || {};
    _.setWith(newCandidateListPageSizeConfig, [currentUserEmail], size, Object);
    localStorage.setItem(localStorageKey, JSON.stringify(newCandidateListPageSizeConfig));
    if (onShowSizeChangeCallBack) {
      onShowSizeChangeCallBack({ page, size });
    }
  };

  const isTotalCountLessThanRequestedPayloadFrom = totalCandidate < (currentPage - 1) * 10;

  const pageRedirectMessage = () => {
    if (totalCandidate && isTotalCountLessThanRequestedPayloadFrom) {
      message.info(<FormattedMessage {...messages.paginationChangeMessage} />, 10);
    }
    return null;
  };

  React.useEffect(() => {
    pageRedirectMessage();
  }, [totalCandidate]);

  const updatedCurrentPage = isTotalCountLessThanRequestedPayloadFrom
    ? previousSuccessfulCandidateFetchedPage
    : currentPage;

  const segmentBuckets = candidateContext === 'segment' && (status === 'Rejected' || status === 'Shortlisted');

  return (
    <div
      className={`${styles.paginationContainer} ${onlyPrevNext ? styles.onlyPrevNext : null} ${shouldRemovePagination ? styles.segmentView : null
        }`}
      style={inlineStyles}
    >
      <Pagination
        size="small"
        pageSize={pageSize}
        current={updatedCurrentPage}
        total={totalCandidate}
        showSizeChanger={!shouldRemovePagination || (candidateContext !== 'segment' && !shouldRemovePagination)}
        onChange={onChange}
        onShowSizeChange={onShowSizeChange}
        hideOnSinglePage={totalCandidate <= 10}
        disabled={disabled}
        pageSizeOptions={pageSizeOptions ?? appConfig.defaultPageSizes.map(x => x.toString())}
        showQuickJumper={!onlyPrevNext && !segmentBuckets && !isExactMatchSearch}
        showTotal={
          showRange
            ? (x, range) => (
              <span>
                {range[0]}-{range[1]} / {totalCandidatesDisplayCount ?? totalCandidate}  <FormattedMessage {...messages.candidatesLabel} />
              </span>
            )
            : null
        }
        itemRender={(current, type, originalElement) =>
          itemRender({
            current,
            type,
            originalElement,
            showPreviousNextButton,
            currentPage,
            isNextButtonDisabled,
            pageSize,
            totalCandidate,
          })
        }
      />
      {!onlyPrevNext && !segmentBuckets && !disabled && totalCandidate > pageSize && !isExactMatchSearch && (
        <span style={{ paddingTop: 2 }}><FormattedMessage {...messages.pageLabel} /></span>
      )}
    </div>
  );
}

export default React.memo(CandidateListPagination);
