import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import CareerBuilderAdvancedOptions from './CareerBuilder/CareerBuilderAdvancedOptions';
import MonsterAdvancedOptions from './Monster/MonsterAdvancedOptions';
import CareerBuilderEdgeAdvancedOptions from './CareerBuilderEdge/CareerBuilderEdgeAdvancedOptions';
import DiceAdvancedOptions from './Dice/DiceAdvancedOptions';
import ClearanceJobsAdvancedOptions from './ClearanceJobs/ClearanceJobsAdvancedOptions';
import NaukriAdvancedOptions from './Naukri/NaukriAdvancedOptions';
import SocialAdvanceOptions from './Social/SocialAdvanceOptions';
import { getMaskingConfig } from '../../../Reducers/ConfigReducer';
import { diversityMessageForNameAndUniversity } from '../../../Utils/ManualSearchUtils';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

const mapStateToProps = (state, props) => ({
  maskingConfig: getMaskingConfig(state),
});

function AdvancedOptions(props) {
  const {
    source,
    form,
    defaultAdvancedSettings,
    searchType,
    currentCompanies,
    monsterClassicCompanyCatagories,
    countryCode,
    isCountryWideSearchEnabled,
    isAlternateLocationEnabled,
    isStateWideSearchEnabled,
    maskingConfig,
    intl,
  } = props;
  if (source?.Portal?.toLowerCase() === 'social') {
    return (
      <SocialAdvanceOptions
        fieldDecoratorValue={'social_source-name'}
        placeholder={intl.formatMessage({ ...message.selectSocialSourceNameLabel })}
        form={form}
        defaultSettings={_.get(defaultAdvancedSettings, 'Social', {})}
      />
    );
  }
  if (source?.Group?.toLowerCase() === 'passive') {
    return (
      <SocialAdvanceOptions
        fieldDecoratorValue={'passive_source-name'}
        placeholder={intl.formatMessage({ ...message.selectPassiveSourceNameLabel })}
        form={form}
        defaultSettings={_.get(defaultAdvancedSettings, 'Passive', {})}
      />
    );
  }
  if (source?.Portal?.toLowerCase() === 'careerbuilder') {
    return (
      <CareerBuilderAdvancedOptions
        form={form}
        defaultSettings={_.get(defaultAdvancedSettings, 'CareerBuilder', {})}
        countryCode={countryCode}
        isCountryWideSearchEnabled={isCountryWideSearchEnabled}
        isAlternateLocationEnabled={isAlternateLocationEnabled}
        isStateWideSearchEnabled={isStateWideSearchEnabled}
      />
    );
  }
  if (source?.Portal?.toLowerCase() === 'monster') {
    return (
      <MonsterAdvancedOptions
        form={form}
        defaultSettings={_.get(defaultAdvancedSettings, 'Monster', {})}
        searchType={searchType}
        currentCompanies={currentCompanies}
        monsterClassicCompanyCatagories={monsterClassicCompanyCatagories}
        maskingConfig={maskingConfig}
        diversityForNameText={diversityMessageForNameAndUniversity}
      />
    );
  }
  if (source?.Portal?.toLowerCase() === 'dice') {
    return (
      <DiceAdvancedOptions
        form={form}
        defaultSettings={_.get(defaultAdvancedSettings, 'Dice', {})}
        searchType={searchType}
        currentCompanies={currentCompanies}
        countryCode={countryCode}
        isCountryWideSearchEnabled={isCountryWideSearchEnabled}
        isAlternateLocationEnabled={isAlternateLocationEnabled}
        isStateWideSearchEnabled={isStateWideSearchEnabled}
      />
    );
  }
  if (source?.Portal?.toLowerCase() === 'clearancejobs') {
    return (
      <ClearanceJobsAdvancedOptions form={form} defaultSettings={_.get(defaultAdvancedSettings, 'ClearanceJobs', {})} />
    );
  }
  if (source?.Portal?.toLowerCase() === 'careerbuilderedge') {
    return (
      <CareerBuilderEdgeAdvancedOptions
        form={form}
        defaultSettings={defaultAdvancedSettings?.CareerBuilderEdge || {}}
        maskingConfig={maskingConfig}
        diversityForNameText={diversityMessageForNameAndUniversity}
      />
    );
  }
  if (source?.Portal?.toLowerCase() === 'naukri') {
    return <NaukriAdvancedOptions form={form} defaultSettings={_.get(defaultAdvancedSettings, 'Naukri', {})} />;
  }

  return null;
}

export default connect(mapStateToProps, null)(injectIntl(Form.create()(AdvancedOptions)));
export { AdvancedOptions as AdvancedOptionsWithoutStore };
