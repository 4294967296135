import React from 'react';
import { Checkbox, Skeleton, Tooltip, Form } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  emailStatsTooltipTexts,
  messageValueMapping,
  emailValueMapping,
  subscriptionStatsValueMapping,
  getNotSentStatusCount,
  getSubscriptionStats,
} from '../../Utils/EmailStatsUtils';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import jobMessage from '../JobForm/JobMessages';

function EmailStatsFilter({
  totalCandidate,
  connectDetails,
  isLoading,
  isEmailNotSentFilterHidden = false,
  conversationStatsForJob,
  subscriptionStatsForJob,
  intl,
}) {
  const {
    EmailUnsubscribedPersonIds: emailUnsubscribedPersonIds,
    MessageConsentDeniedPersonIds: messageConsentDeniedPersonIds,
    MessageConsentApprovedPersonIds: messageConsentApprovedPersonIds,
  } = subscriptionStatsForJob;
  const emailStats = conversationStatsForJob?.EmailStats;
  const messageStats = conversationStatsForJob?.MessageStats;
  const emailNotSentCandidateCount = getNotSentStatusCount(emailStats);
  const messageNotSentCandidateCount = getNotSentStatusCount(messageStats);
  const subscriptionStats = getSubscriptionStats(
    emailUnsubscribedPersonIds,
    messageConsentDeniedPersonIds,
    messageConsentApprovedPersonIds
  );
  const conversationList = connectDetails
    ?.filter(item => item.ConversationId !== null && item.ConversationId !== undefined)
    .map(item => item.ConversationId);
  const messageNotSentCount = totalCandidate - conversationList?.length + messageNotSentCandidateCount;
  const emailNotSentCount = totalCandidate - conversationList?.length + emailNotSentCandidateCount;
  const emailStatsTitles = {
    Sent: intl.formatMessage({ ...jobMessage.emailSentLabel }),
    Opened: intl.formatMessage({ ...jobMessage.emailOpenedLabel }),
    NotOpened: intl.formatMessage({ ...jobMessage.emailNotOpenedLabel }),
    Clicked: intl.formatMessage({ ...jobMessage.emailClickedLabel }),
    Replied: intl.formatMessage({ ...jobMessage.emailRepliedLabel }),
    NotSent: intl.formatMessage({ ...jobMessage.emailNotSentLabel }),
    Pending: intl.formatMessage({ ...jobMessage.emailPendingLabel }),
    Failed: intl.formatMessage({ ...jobMessage.emailFailedLabel }),
  };
  const messageStatsTitles = {
    Sent: intl.formatMessage({ ...jobMessage.messageSentLabel }),
    Failed: intl.formatMessage({ ...jobMessage.messageFailedLabel }),
    NotSent: intl.formatMessage({ ...jobMessage.messageNotSentLabel }),
    Skipped: intl.formatMessage({ ...jobMessage.messageSkippedLabel }),
    Pending: intl.formatMessage({ ...jobMessage.messagePendingLabel }),
  };
  const unsubscribedStatsTitles = {
    EmailUnsubscribed: intl.formatMessage({ ...jobMessage.emailUnsubscribedLabel }),
    MessageConsentDenied: intl.formatMessage({ ...jobMessage.messageConsentDeniedLabel }),
    Skipped: intl.formatMessage({ ...jobMessage.messageSkippedLabel }),
    Pending: intl.formatMessage({ ...jobMessage.messagePendingLabel }),
    MessageConsentApproved: intl.formatMessage({ ...jobMessage.messageConsentApprovedLabel }),
  };

  return (
    <Skeleton loading={isLoading} paragraph={{ rows: 1 }}>
      {emailStats?.map(emailStatus =>
        (!isEmailNotSentFilterHidden && emailStatus?.Status === 'NotSent' && emailNotSentCount > 0) ||
        (emailStatus?.EmailCount > 0 && emailStatus?.ConversationCount > 0) ? (
          <div key={emailStatus.Status}>
            <Tooltip title={emailStatsTooltipTexts[emailStatus?.Status]}>
              <Checkbox style={{ marginBottom: 8 }} value={emailValueMapping[emailStatus.Status]}>
                {emailStatsTitles[emailStatus.Status]}{' '}
                {emailStatus?.Status === 'NotSent' ? (
                  <span style={{ display: 'inline-flex', gap: 2 }}>
                    {`(${emailNotSentCount})`}
                    <span style={{ marginTop: '1px' }}>
                      <InfoIconWithTooltip
                        tooltipTitle={
                          <span>
                            <FormattedMessage {...jobMessage.totalNumberOfCandidatesToWhomEmailNotSentLabel} />
                          </span>
                        }
                      />
                    </span>
                  </span>
                ) : (
                  `(${emailStatus?.ConversationCount} | ${emailStatus?.EmailCount})`
                )}
              </Checkbox>
            </Tooltip>
          </div>
        ) : null
      )}
      {messageStats?.map(messageStatus =>
        (messageStatus?.Status === 'NotSent' && messageNotSentCount > 0) ||
        (messageStatus?.MessageCount > 0 && messageStatus?.ConversationCount > 0) ? (
          <div key={messageStatus.Status}>
            <Checkbox style={{ marginBottom: 8 }} value={messageValueMapping[messageStatus.Status]}>
              {messageStatsTitles[messageStatus.Status]}{' '}
              {messageStatus?.Status === 'NotSent' ? (
                <span style={{ display: 'inline-flex', gap: 2 }}>
                  {`(${messageNotSentCount})`}
                  <span style={{ marginTop: '1px' }}>
                    <InfoIconWithTooltip
                      tooltipTitle={
                        <span>
                          <FormattedMessage {...jobMessage.totalNumberOfCandidatesToWhomMessageNotSentLabel} />
                        </span>
                      }
                    />
                  </span>
                </span>
              ) : (
                `(${messageStatus?.ConversationCount} | ${messageStatus?.MessageCount})`
              )}
            </Checkbox>
          </div>
        ) : null
      )}
      {subscriptionStats?.map(stats =>
        stats?.Count > 0 ? (
          <div key={stats.Status}>
            <Checkbox style={{ marginBottom: 8 }} value={subscriptionStatsValueMapping[stats.Status]}>
              {unsubscribedStatsTitles[stats.Status]} {`(${stats.Count})`}
            </Checkbox>
          </div>
        ) : null
      )}
    </Skeleton>
  );
}

export default injectIntl(Form.create()(EmailStatsFilter));
export {EmailStatsFilter as EmailStatsFilterWithoutInjectIntl}
