import React from 'react';
import { Checkbox, Input, Menu, Dropdown, Spin, Form } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import _, { debounce } from 'lodash';
import { ScoutingAgentOutlinedIcon, SaveIconOutlinedIcon } from '../../../../Icons/AryaIcons';
import { EllipsedText } from '../../../../Utils/TextUtils';
import styles from './SearchCriteriaSelectWithAgent.module.scss';
import messages from '../../ManualSearchMessages';

const { Search } = Input;

function SearchCriteriaSelectWithAgent(props) {
  const {
    criteriaIdentifiers,
    fetchManualSearchCriteriaIdentifiers,
    clearManualSearchCriteriaIdentifiers,
    fetchManualSearchCriteriaIdentifiersApiStatus,
    jobId,
    onSelect,
    intl,
  } = props;
  const dropdownMenuRef = React.createRef(null);
  const [searchTerm, setSearchTerm] = React.useState();
  const [manualSearchVisibleFlag, setManualSearchVisibleFlag] = React.useState(false);
  const [searchFilterOptions, setSearchFilterOptions] = React.useState(['Criteria', 'Agent']);

  const { Suggestions: criteriaIdentifiersSuggestions = [], Count: totalCount = 0 } = criteriaIdentifiers;
  const _fetchManualSearchCriteriaIdentifiers = debounce(fetchManualSearchCriteriaIdentifiers, 600);

  const handleSearch = debounce(value => {
    if (value) {
      if (criteriaIdentifiersSuggestions.length) clearManualSearchCriteriaIdentifiers({ jobId, criteriaType: 'Saved' });
      fetchManualSearchCriteriaIdentifiers({
        payload: { jobId, searchTerm: value },
        isOrgLevelSearch: true,
        criteriaType: 'Saved',
      });
    }
  }, 600);

  const handleChange = e => {
    setSearchTerm(e.target.value);
    handleSearch(e.target.value);
  };

  const handleVisibleChange = flag => {
    setManualSearchVisibleFlag(flag);
  };

  const handleScroll = e => {
    if (fetchManualSearchCriteriaIdentifiersApiStatus === 'INPROGRESS') return;
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 &&
      criteriaIdentifiersSuggestions.length < totalCount
    ) {
      _fetchManualSearchCriteriaIdentifiers({
        payload: { jobId, searchTerm, from: criteriaIdentifiersSuggestions.length, size: 10 },
        isOrgLevelSearch: !!searchTerm,
        criteriaType: 'Saved',
      });
    }
  };

  const handleOnFocus = () => {
    if (_.isEmpty(criteriaIdentifiersSuggestions) && fetchManualSearchCriteriaIdentifiersApiStatus !== 'INPROGRESS')
      fetchManualSearchCriteriaIdentifiers({ payload: { jobId }, criteriaType: 'Saved' });
  };

  const handleSelect = ({ key }) => {
    if (key) {
      setSearchTerm(undefined);
      onSelect(key);
      setManualSearchVisibleFlag(false);
    } else {
      handleVisibleChange();
    }
  };

  const onChangeFilter = e => {
    const filterValue = e.target.value;
    if (searchFilterOptions.includes(filterValue)) {
      setSearchFilterOptions(searchFilterOptions.filter(filter => filter !== filterValue));
    } else {
      setSearchFilterOptions([...searchFilterOptions, filterValue]);
    }
  };
  const filteredSuggestions = criteriaIdentifiersSuggestions.filter(criteria => {
    if (criteria?.IsDeleted === true) {
      return false;
    }
    return searchFilterOptions.some(filter => {
      return criteria[`${filter}Id`] || criteria[`${filter}Name`];
    });
  });
  const icons = {
    Criteria: <SaveIconOutlinedIcon style={{ fontSize: '16px', color: '#B4B7BA' }} />,
    Agent: <ScoutingAgentOutlinedIcon width="20" height="20" style={{ fontSize: '16px', color: '#B4B7BA' }} />,
  };
  const menu = () => {
    return (
      <Menu onClick={handleSelect} ref={dropdownMenuRef}>
        <div className={styles.filterContainer}>
          <Checkbox value="Criteria" checked={searchFilterOptions.includes('Criteria')} onChange={onChangeFilter}>
            <FormattedMessage {...messages.savedCriteriaLabel} />
          </Checkbox>
          <Checkbox value="Agent" checked={searchFilterOptions.includes('Agent')} onChange={onChangeFilter}>
            <FormattedMessage {...messages.agentLabel} />
          </Checkbox>
        </div>
        <Menu.Divider
          style={
            filteredSuggestions.length === 0 ? { display: 'none' } : { boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)' }
          }
        ></Menu.Divider>
        {filteredSuggestions.length > 0 && (
          <div className={styles.recentHeading}>
            <FormattedMessage {...messages.recentOneLabel} />
          </div>
        )}
        {fetchManualSearchCriteriaIdentifiersApiStatus !== 'INPROGRESS' &&
          filteredSuggestions.map(criteria => {
            const key = criteria[`${searchFilterOptions[0]}Id`] || criteria[`${searchFilterOptions[1]}Id`];
            const name = criteria[`${searchFilterOptions[0]}Name`] || criteria[`${searchFilterOptions[1]}Name`];
            const iconType = Object.keys(criteria).includes('CriteriaId') ? 'Criteria' : 'Agent';
            const createdBy = criteria.CreatedBy;
            const getTime = moment.utc(criteria.CreatedOn).local().format('hh:mm A | DD MMM YY');
            return (
              <Menu.Item key={key} value={key}>
                <div className={styles.optionContainer}>
                  <div className={styles.nameAndIcon}>
                    <div className={styles.iconDisplay}>{icons[iconType]}</div>
                  </div>
                  <div className={styles.agentNameAndInfo}>
                    <div className={styles.agentName}>
                      <EllipsedText text={name} maxTextLength={41} mouseEnterDelay={1} />
                    </div>
                    <small>
                      <FormattedMessage {...messages.createdByLabel} />{' '}
                      <EllipsedText text={createdBy} maxTextLength={27} mouseEnterDelay={1} />{' '}
                      <FormattedMessage {...messages.onLabel} /> {getTime}
                    </small>
                  </div>
                </div>
              </Menu.Item>
            );
          })}
        {fetchManualSearchCriteriaIdentifiersApiStatus === 'INPROGRESS' && (
          <Menu.Item key="spin">
            <div className={styles.spinLoaderContainer}>
              <Spin className={styles.spinLoader} size="small" />
              <span className={styles.spinLoaderText}>
                <FormattedMessage {...messages.fetchingSavedCriteriasLabel} />
              </span>
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };
  const menu2 = <Menu style={{ display: 'none' }}></Menu>;

  return (
    <Dropdown
      overlay={criteriaIdentifiersSuggestions.length > 0 ? menu : menu2}
      trigger="click"
      getPopupContainer={node => node.parentNode}
      onVisibleChange={handleVisibleChange}
      visible={manualSearchVisibleFlag}
      placement="bottomRight"
      ref={dropdownMenuRef}
      onPopupScroll={handleScroll}
      overlayClassName={styles.dropdownContainer}
    >
      <Search
        allowClear
        className={styles.searchCriteria}
        placeholder={intl.formatMessage({ ...messages.searchCriteriaPlaceholder })}
        onScroll={handleScroll}
        onFocus={handleOnFocus}
        onChange={handleChange}
        onSearch={handleSearch}
        value={searchTerm}
        style={{ width: 232 }}
      />
    </Dropdown>
  );
}

export default injectIntl(Form.create()(SearchCriteriaSelectWithAgent));
