import React from 'react';
import { Empty ,Form} from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import '../../../Components/CandidateList/CandidateList.scss';
import { AryaDeactivatedSvg } from '../../../Icons/CandidateListEmptyScreenIcons';
import { EmptyCandidatesSvg } from '../../../Icons/AryaIcons';
import messages from '../../../Components/Placeholders/PlaceholdersMessages';


const CandidateListEmptyScreen = props => {
  const { clearAllFilter, isFilterApplied, showSourcingInprogress, AryaAutoSourcing, deactivated,intl } = props;

  function getCandidateListMessage() {
    let message;
    let imageComponent;
    let newNoCandidatesFoundMessage = '';
    let classNameForMessage = 'candidate-list-no-candidates-found';
    let classNameForDescription = 'candidate-list-no-candidates-found-message';

    const isSourcingInProgressWithoutFilter = !isFilterApplied && showSourcingInprogress;

    if (isSourcingInProgressWithoutFilter) {
      message = <FormattedMessage {...messages.scoringRankingIsInProgressLabel}/>;
      imageComponent = (
        <img
          className="candidate-empty-list"
          src={`${process.env.PUBLIC_URL}/static/Images/search.gif`}
          alt={intl.formatMessage({ ...messages.sourcingInProgressLabel })}
        />
      );
    } else if (!showSourcingInprogress && !isFilterApplied && !AryaAutoSourcing) {
      message = <FormattedMessage {...messages.pleaseClickOnScoreAndRankLabel}/>;
      imageComponent = <AryaDeactivatedSvg className="candidate-empty-list" />;
    } else if (deactivated) {
      message = <FormattedMessage {...messages.scoreAndRankIsDeactivatedLabel}/>;
      imageComponent = <AryaDeactivatedSvg className="candidate-empty-list" />;
      newNoCandidatesFoundMessage = <FormattedMessage {...messages.pleaseClickOnScoreAndRankLabel}/>;
    } else {
      message = <FormattedMessage {...messages.noCandidatesFoundLabel}/>;
      imageComponent = <EmptyCandidatesSvg className="candidate-empty-list" />;
      newNoCandidatesFoundMessage = <FormattedMessage {...messages.pleaseChangeYourCriteriaLabel}/>;
    }

    if (deactivated) {
      classNameForMessage = 'score-and-rank-deactivated-message';
      classNameForDescription = 'candidate-list-no-candidates-found';
    }
    return {
      message,
      imageComponent,
      newNoCandidatesFoundMessage,
      classNameForMessage,
      classNameForDescription,
    };
  }

  const { message, imageComponent, newNoCandidatesFoundMessage, classNameForMessage, classNameForDescription } =
    getCandidateListMessage();

  return (
    <div style={{ padding: '100px' }}>
      <Empty
        image={imageComponent}
        description={
          <div>
            <div className={classNameForMessage} data-testid="message-container">
              {message}
            </div>
            {!isFilterApplied ? (
              <div className={classNameForDescription} data-testid="description-container">
                {newNoCandidatesFoundMessage}
              </div>
            ) : (
              <div
                className="candidate-list-remove-filter"
                role="button"
                tabIndex={0}
                onClick={clearAllFilter}
                onKeyPress={clearAllFilter}
              >
                <FormattedMessage {...messages.clearFilterLabel}/>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default (injectIntl(Form.create()(CandidateListEmptyScreen)));
