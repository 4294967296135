import React from 'react';
import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './SegmentBulkShortlistConfirmationModal.module.scss';
import { extractNumberFromText } from '../../Utils/TextUtils';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

export default function SegmentBulkShortlistErrorModal(props) {
  const { visible, errorMessage, isBulkIconActivated, eventSegmentModalOnErrorCallbacks, isAddIconloading } = props;
  const extractedNumber = extractNumberFromText(errorMessage);
  return (
    <div className={styles.modalContainer}>
      <Modal
        visible={visible}
        onCancel={eventSegmentModalOnErrorCallbacks.onModalCancel}
        maskClosable={false}
        footer={[
          <div className={styles.actionButton}>
            <Button
              type="default"
              shape="round"
              style={{ marginRight: '8px' }}
              onClick={eventSegmentModalOnErrorCallbacks.onCancel}
              key="exclude-button"
            >
              <FormattedMessage {...message.cancelLabel} />
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={e => eventSegmentModalOnErrorCallbacks.onSubmit(e, isBulkIconActivated)}
              key="association-button"
              loading={isAddIconloading}
            >
              <FormattedMessage {...message.AddLabel} />
            </Button>
          </div>,
        ]}
        width={500}
        zIndex={2050}
        closable={false}
      >
        <div className={styles.bulkConfirmation}>
          <div className={styles.bulkConfirmationTitle}>
            <FormattedMessage {...message.maximumNoOfCandidateIncludedToASegmentLabel} values={{ extractedNumber }} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
