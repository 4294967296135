import React from 'react';
import _ from 'lodash';
import { Icon, Button, Alert, Checkbox, Popover, message, Input, Modal, Form } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { getEmailAddressWithEmailTypeIcon, getSortedEmailsByEmailTypes } from '../../Utils/EmailUtils/EmailUtils';
import styles from './AvailableContactInformation.module.scss';
import AddEmail from '../PullContact/AddEmail/AddEmail';
import {
  validateStatus,
  checkIfAnyNonRefundedPulledContactsExists,
  getLastRefundTime,
  getSortedPhonesByTypes,
  getIsContactMismatch,
} from '../../Utils/ContactUtils';
import AddPhoneNumber from '../PullContact/AddPhoneNumber/AddPhoneNumber';
import SMSIcon from '../../Icons/SMSIcon';
import CopyIcon from '../../Icons/CopyIcon';
import CallIcon from '../../Icons/CallIcon';
import { getEllipsedText } from '../../Utils/TextUtils';
import { getCandidateOptOutReason } from '../../Utils/CandidateRejectReasonsUtils';
import { getIsCandidateCommunicationDisabled } from '../../Utils/ConnectUtils';
import { isPulseUser } from '../../Utils/ConfigUtils';
import candidateDetailMessage from '../../Containers/CandidateDrawer/messages';

const { TextArea } = Input;

function AvailableContactInformation(props) {
  const {
    candidate,
    connectInfo,
    addContact,
    jobGuid,
    getRefundForInvalidContacts,
    creditsRefunded,
    mailRef,
    smsRef,
    callRef,
    onSubTabClick,
    setEmailComposeModalVisibility,
    setMessageComposeModalVisibility,
    setWorkflowComposeModalVisibility,
    candidateWorkflowHistory,
    setCurrentSelectedEmail,
    handleValidityStatusUpdate,
    handlePhoneValidityStatusUpdate,
    usersById,
    featureToggleList,
    setWorkflowSelectedEmails,
    intl,
  } = props;
  const isContactValidityStatusUpdateFeatureEnabled = featureToggleList.ContactValidityStatusUpdate.IsEnabled; // ContactValidityStatusUpdate
  const [isAddEmailFormVisible, setAddEmailFormVisibility] = React.useState(false);
  const [isAddPhoneFormVisible, setAddPhoneFormVisibility] = React.useState(false);
  const [isDetailsVisible, setDetailsVisibility] = React.useState(true);
  const [requestCreditFlag, setRequestCreditFlag] = React.useState(true);
  const [isRefundedCreditsVisible, setRefundedCreditsVisibility] = React.useState(false);
  const [emailSeeMore, setEmailSeeMore] = React.useState(true);
  const [phoneSeeMore, setPhoneSeeMore] = React.useState(true);
  const [currentSelectedInvalidEmail, setCurrentSelectedInvalidEmail] = React.useState(null);
  const [currentSelectedInvalidPhone, setCurrentSelectedInvalidPhone] = React.useState(null);
  const [mismatchReason, setMismatchReason] = React.useState(null);
  const [phoneValidityStatusRemark, setPhoneValidityStatusRemark] = React.useState(null);
  const [isReportInvalidEmailButtonClicked, setIsReportInvalidEmailButtonClicked] = React.useState(false);
  const [isReportInvalidPhoneButtonClicked, setIsReportInvalidPhoneButtonClicked] = React.useState(false);
  const isSubmitButtonDisabled = !mismatchReason?.trim()?.length && !phoneValidityStatusRemark?.trim()?.length;
  const isPulse = isPulseUser();
  const maxEmailLength = 30;
  const connectStatuses = connectInfo.ConnectStatuses || {};
  const connectStatus = candidate ? _.get(connectStatuses, candidate.PersonId, {}) : {};
  const subscription = connectStatus?.contact?.Subscription;

  const areContactsAvailable =
    _.get(connectStatus, ['Contact', 'Emails'], []).length || _.get(connectStatus, ['Contact', 'Phones'], []).length;
  const phones = _.get(connectStatus, 'Contact.Phones', []);

  const isCandidateRequestStatusSuccessful = _.get(connectStatus, ['Contact', 'RequestStatus'], '') === 'Success';
  const maxContactToBeShownInitially = 4;
  const isWorkflowWindowEnabled = featureToggleList.CandidateLevelWorkflow.IsEnabled;

  const onEmailAddClick = () => {
    setAddEmailFormVisibility(true);
  };

  const addEmail = value => {
    const contactObject = {
      Emails: [
        {
          EmailAddress: value,
          IsAddedByUser: true,
          ValidityStatus: 'Valid',
        },
      ],
    };
    addContact(jobGuid, candidate.PersonId, contactObject);
    setAddEmailFormVisibility(false);
  };

  const onPhoneAddClick = () => {
    setAddPhoneFormVisibility(true);
  };

  const addPhone = value => {
    const contactObject = {
      Phones: [
        {
          Number: value,
          IsAddedByUser: true,
          ValidityStatus: 'Valid',
        },
      ],
    };
    addContact(jobGuid, candidate.PersonId, contactObject);
    setAddPhoneFormVisibility(false);
  };

  const handleCopyContact = contact => {
    const value = contact.EmailAddress || contact.Number;
    navigator.clipboard.writeText(value);
    const successMessage = contact.EmailAddress
      ? intl.formatMessage({ ...candidateDetailMessage.emailCopiedLabel })
      : intl.formatMessage({ ...candidateDetailMessage.phoneNumberCopiedLabel });
    message.success(successMessage, 1);
  };

  const isCandidateCommunicationDisabled = getIsCandidateCommunicationDisabled(candidate?.PersonId, connectInfo);

  const handleSendEmail = async email => {
    await onSubTabClick('email');
    mailRef.current.onChangeMail(email.EmailAddress);
    setCurrentSelectedEmail(email.EmailAddress);
    if (!isCandidateCommunicationDisabled) setEmailComposeModalVisibility(true);
  };

  const handleSendSMS = async phone => {
    await onSubTabClick('message');
    smsRef.current.onChangePhone(phone.Number);
    if (!isCandidateCommunicationDisabled) setMessageComposeModalVisibility(true);
  };
  const candidateOptOutReason = getCandidateOptOutReason(candidate);
  const isCommunicationAllowed =
    !(phones || []).some(phone => !phone.IsCommunicationAllowed) &&
    !candidateOptOutReason &&
    subscription?.Status?.toLowerCase() !== 'unsubscribed';

  const handleCall = async phone => {
    await onSubTabClick('call');
    callRef.current.onPhoneSelection(phone.Number);
    if (isCommunicationAllowed && !isCandidateCommunicationDisabled) callRef.current.initiateCall();
  };

  const handleWorkflow = async email => {
    await onSubTabClick('workflow');
    mailRef.current.onChangeMail(email.EmailAddress);
    setCurrentSelectedEmail(email.EmailAddress);
    setWorkflowSelectedEmails([email.EmailAddress]);
    if (!isCandidateCommunicationDisabled) setWorkflowComposeModalVisibility(true);
  };

  const handleInvalidStatusReason = e => {
    setMismatchReason(e.target.value);
  };

  const handleReportInvalidEmailButtonClick = emailAddress => {
    setCurrentSelectedInvalidEmail(emailAddress);
    setIsReportInvalidEmailButtonClicked(true);
  };

  const handleReportInvalidPhoneButtonClick = phoneNumber => {
    setCurrentSelectedInvalidPhone(phoneNumber);
    setIsReportInvalidPhoneButtonClicked(true);
  };

  const handleEmailStatusUpdate = (personId, emailAddress) => {
    setCurrentSelectedInvalidEmail(null);
    setIsReportInvalidEmailButtonClicked(false);
    if (mismatchReason !== '' || mismatchReason !== null) {
      handleValidityStatusUpdate(personId, emailAddress, 'Invalid', mismatchReason);
    }
  };

  const handleSubmitButtonClick = ({ personId, emailAddress, type, phoneNumber }) => {
    const modalOptions = {
      title: `Are you sure you want to report this ${type} as invalid?`,
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      okText: 'Report Invalid',
      onOk: () =>
        type === 'email'
          ? handleEmailStatusUpdate(personId, emailAddress)
          : handlePhoneStatusUpdate(personId, phoneNumber),
      zIndex: 2200,
      centered: true,
      width: 400,
    };
    Modal.confirm(modalOptions);
  };
  const formatDate = dateString => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
    return date.toLocaleDateString(undefined, options);
  };
  function truncateString(inputString) {
    if (inputString.length <= 30) {
      return inputString;
    }
    return `${inputString.substring(0, 30)}...`;
  }

  const getEmailPopoverContent = ({ email, emailTooltip }) => {
    let content;

    const actionButtons = (
      <div className={styles.actionButtons}>
        <Button type="link" onClick={() => handleSendEmail(email)}>
          <div className={styles.smsIconAndText}>
            <SMSIcon />
            <span>
              <FormattedMessage {...candidateDetailMessage.sendEmailButton} />
            </span>
          </div>
        </Button>
        <Button type="link" onClick={() => handleCopyContact(email)}>
          <div className={styles.copyIconAndText}>
            <CopyIcon />
            <span>
              <FormattedMessage {...candidateDetailMessage.copyEmailButton} />
            </span>
          </div>
        </Button>
        {isWorkflowWindowEnabled && (
          <Button disabled={candidateWorkflowHistory?.length > 0} type="link" onClick={() => handleWorkflow(email)}>
            <div className={styles.smsIconAndText}>
              <SMSIcon />
              <span>
                <FormattedMessage {...candidateDetailMessage.initiateWorkFlowLabel} />
              </span>
            </div>
          </Button>
        )}
      </div>
    );

    if (!isContactValidityStatusUpdateFeatureEnabled) return actionButtons;

    if (currentSelectedInvalidEmail) {
      content = (
        <div className={styles.remarkForInvalidEmail}>
          <div className={styles.remarkMandate}>
            <span>*</span>
            <FormattedMessage {...candidateDetailMessage.remarksLabel} />
          </div>
          <TextArea
            onChange={handleInvalidStatusReason}
            rows={4}
            placeholder={`Enter Remarks\n(max 70 characters)`}
            data-testid="email-invalid-reason"
            maxLength={70}
          />
          <div className={styles.remarkSubmitButton}>
            <Button
              size="small"
              type="primary"
              shape="round"
              onClick={() => {
                handleSubmitButtonClick({
                  personId: candidate.PersonId,
                  emailAddress: email.EmailAddress,
                  type: 'email',
                });
              }}
              disabled={isSubmitButtonDisabled}
            >
              <FormattedMessage {...candidateDetailMessage.submitLabel} />
            </Button>
          </div>
        </div>
      );
    } else if (email.ContactMismatchInfo?.IsMismatch) {
      const { ContactMismatchInfo: contactMismatchInfo } = email;
      const modifierUser = Object?.values(usersById)?.find(
        user => user.UserGuid === contactMismatchInfo.LastModifiedBy
      );
      if (modifierUser) {
        content = (
          <div className={styles.invalidPopover} data-testid="invalid-email-popover">
            <div>
              <span className={styles.invalidPopoverAttribute}>
                {' '}
                <FormattedMessage {...candidateDetailMessage.markedInvalidByLabel} />:{' '}
              </span>{' '}
              {truncateString(modifierUser.FullName)}
            </div>
            <div>
              <b>
                <FormattedMessage {...candidateDetailMessage.dateLabel} />:{' '}
              </b>
              {formatDate(contactMismatchInfo.LastUpdatedTime)}{' '}
            </div>
            <div>
              <span className={styles.invalidPopoverAttribute}>
                <FormattedMessage {...candidateDetailMessage.remarksLabel} />:
              </span>{' '}
              {contactMismatchInfo.MismatchReason}
            </div>
          </div>
        );
      } else {
        content = actionButtons;
      }
    } else {
      content = (
        <div className={styles.manuallyInvalidEmailContent}>
          {actionButtons}
          <div className={styles.reportInvalidEmailButton}>
            <Button
              type="link"
              onClick={() => {
                handleReportInvalidEmailButtonClick(email.EmailAddress);
              }}
            >
              <div className={styles.invalidEmailIcon}>
                <Icon type="exclamation-circle" className={styles.exclamationIcon} />
                <span className={styles.reportInvalidTextInEmail}>
                  <FormattedMessage {...candidateDetailMessage.reportInvalidLabel} />
                </span>
              </div>
            </Button>
          </div>
        </div>
      );
    }
    return content;
  };

  const handleEmailPopoverVisibility = visible => {
    if (!visible) {
      setCurrentSelectedInvalidEmail(null);
      setMismatchReason(null);
      setIsReportInvalidEmailButtonClicked(false);
    }
  };

  const getEmails = () => {
    const emails = connectStatus?.Contact?.Emails ?? [];
    const sortedEmails = getSortedEmailsByEmailTypes({ emails });
    const updatedSortedEmails = emailSeeMore ? sortedEmails?.slice(0, maxContactToBeShownInitially) : sortedEmails;
    return updatedSortedEmails.map(email => {
      const emailRefundStatus = _.get(email, ['IsRefunded'], false);
      const { text, displayedText } = getEllipsedText({ text: email.EmailAddress, maxTextLength: maxEmailLength });
      const emailAddress = displayedText || text;
      const updatedEmail = { ...email, EmailAddress: emailAddress, Type: email.Type };
      const emailTooltip = displayedText ? text : null;
      const popoverContent = getEmailPopoverContent({ email, emailTooltip });
      const invalidReason = email?.ContactMismatchInfo?.MismatchReason;
      const invalidContactExtaStyle = invalidReason ? styles.lineForInvalidContact : '';
      const mail = (
        <div className={styles.emailContent}>
          <div
            className={
              validateStatus(email.ValidityStatus) || getIsContactMismatch(email.ContactMismatchInfo)
                ? `${styles.invalidEmail} ${invalidContactExtaStyle}`
                : ''
            }
          >
            {getEmailAddressWithEmailTypeIcon({
              email: updatedEmail,
              popoverContent,
              onVisibilityChange: handleEmailPopoverVisibility,
              isReportInvalidEmailButtonClicked,
            })}
          </div>
          {email.IsAddedByUser ? <div className={styles.externalContact}>M</div> : null}
          <div className={styles.forwardSlash}>&nbsp;/&nbsp;</div>
        </div>
      );
      return requestCreditFlag || _.get(email, ['IsAddedByUser'], false) || emailRefundStatus ? (
        mail
      ) : (
        <Checkbox className={styles.checkbox} defaultChecked disabled>
          {mail}
        </Checkbox>
      );
    });
  };

  const handleInvalidPhoneReason = e => {
    setPhoneValidityStatusRemark(e.target.value);
  };
  const handlePhoneStatusUpdate = (personId, phoneNumber) => {
    setCurrentSelectedInvalidPhone(null);
    setIsReportInvalidPhoneButtonClicked(false);
    if (phoneValidityStatusRemark !== '' || phoneValidityStatusRemark !== null) {
      handlePhoneValidityStatusUpdate(personId, phoneNumber, 'Invalid', phoneValidityStatusRemark);
    }
  };
  const handlePhonePopoverVisibility = visible => {
    if (!visible) {
      setCurrentSelectedInvalidPhone(null);
      setPhoneValidityStatusRemark(null);
      setIsReportInvalidPhoneButtonClicked(false);
    }
  };

  const getPhonePopoverContent = phone => {
    const { ContactMismatchInfo: contactMismatchInfo } = phone;
    if (contactMismatchInfo?.IsMismatch) {
      const usersArray = Object.values(usersById);
      const modifierUser = usersArray.find(user => user.UserGuid === contactMismatchInfo.LastModifiedBy);
      if (!modifierUser) {
        return null;
      }
      return (
        <div className={styles.invalidPopover} data-testid="invalid-phone-popover">
          <div>
            <span className={styles.invalidPopoverAttribute}>
              <FormattedMessage {...candidateDetailMessage.markedInvalidByLabel} />:
            </span>{' '}
            {truncateString(modifierUser.FullName)}
          </div>
          <div>
            <b>
              <FormattedMessage {...candidateDetailMessage.dateLabel} />:{' '}
            </b>
            {formatDate(contactMismatchInfo.LastUpdatedTime)}{' '}
          </div>
          <div>
            <span className={styles.invalidPopoverAttribute}>
              <FormattedMessage {...candidateDetailMessage.remarksLabel} />:
            </span>{' '}
            {contactMismatchInfo.MismatchReason}
          </div>
        </div>
      );
    }
    if (currentSelectedInvalidPhone) {
      return (
        <div className={styles.remarkForInvalidEmail}>
          <div className={styles.remarkMandate}>
            <span>*</span>
            <FormattedMessage {...candidateDetailMessage.remarksLabel} />
          </div>
          <TextArea
            onChange={handleInvalidPhoneReason}
            rows={4}
            placeholder={`Enter Remarks\n(max 70 characters)`}
            data-testid="email-invalid-reason"
            maxLength={70}
          />
          <div className={styles.remarkSubmitButton}>
            <Button
              size="small"
              type="primary"
              shape="round"
              data-testid="button-invalid-reason"
              onClick={() => {
                handleSubmitButtonClick({ personId: candidate.PersonId, phoneNumber: phone.Number, type: 'number' });
              }}
              disabled={isSubmitButtonDisabled}
            >
              <FormattedMessage {...candidateDetailMessage.submitLabel} />
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.actionButtons}>
        {isPulse ? null : (
          <Button type="link" onClick={() => handleCall(phone)}>
            <div className={styles.callIconAndText}>
              <CallIcon />
              <span>
                <FormattedMessage {...candidateDetailMessage.makeCallLabel} />
              </span>
            </div>
          </Button>
        )}
        {isPulse ? null : (
          <Button
            type="link"
            onClick={() => {
              handleSendSMS(phone);
            }}
          >
            <div className={styles.smsIconAndText}>
              <SMSIcon />
              <span>
                <FormattedMessage {...candidateDetailMessage.sendTextButton} />
              </span>
            </div>
          </Button>
        )}

        <Button type="link" onClick={() => handleCopyContact(phone)}>
          <div className={styles.copyIconAndText}>
            <CopyIcon />
            <span>
              <FormattedMessage {...candidateDetailMessage.copyPhoneNumberButton} />
            </span>
          </div>
        </Button>

        {isContactValidityStatusUpdateFeatureEnabled ? (
          <Button
            type="link"
            onClick={() => {
              handleReportInvalidPhoneButtonClick(phone.Number);
            }}
          >
            <div className={styles.invalidPhone}>
              <Icon type="exclamation-circle" style={{ color: 'grey' }} />
              <span>
                <FormattedMessage {...candidateDetailMessage.reportInvalidLabel} />
              </span>
            </div>
          </Button>
        ) : null}
      </div>
    );
  };
  const getPhones = () => {
    const sortedPhones = getSortedPhonesByTypes({ phones });
    const updatedPhones = phoneSeeMore ? sortedPhones?.slice(0, maxContactToBeShownInitially) : sortedPhones;
    return updatedPhones.map(phone => {
      const phoneRefundStatus = _.get(phone, ['IsRefunded'], false);
      const invalidReason = phone?.ContactMismatchInfo?.MismatchReason;
      const invalidContactExtaStyle = invalidReason ? styles.lineForInvalidContact : '';
      const currentPhone = (
        <div className={styles.phoneContent}>
          <div
            className={
              validateStatus(phone.ValidityStatus) || getIsContactMismatch(phone.ContactMismatchInfo)
                ? `${styles.invalidNumber} ${invalidContactExtaStyle}`
                : null
            }
          >
            {phone.Number}
          </div>
          {phone.IsAddedByUser ? <div className={styles.externalContact}>M</div> : null}
          <div className={styles.forwardSlash}>&nbsp;/&nbsp;</div>
        </div>
      );
      const phoneDisplayed =
        requestCreditFlag || phone.IsAddedByUser || phoneRefundStatus ? (
          currentPhone
        ) : (
          <Checkbox className={styles.checkbox} defaultChecked disabled>
            {currentPhone}
          </Checkbox>
        );
      return !phone.IsRefunded ? (
        <Popover
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.5}
          overlayStyle={{ paddingBottom: '0px' }}
          placement="topLeft"
          content={getPhonePopoverContent(phone)}
          onVisibleChange={handlePhonePopoverVisibility}
          trigger={isReportInvalidPhoneButtonClicked ? 'click' : 'hover'}
        >
          {phoneDisplayed}
        </Popover>
      ) : (
        phoneDisplayed
      );
    });
  };

  const onExpand = () => {
    setAddEmailFormVisibility(false);
    setAddPhoneFormVisibility(false);
    setDetailsVisibility(!isDetailsVisible);
  };

  const requestCredit = () => {
    setRequestCreditFlag(!requestCreditFlag);
  };

  const onConfirmCreditRequest = () => {
    getRefundForInvalidContacts(jobGuid, candidate.PersonId);
    setRequestCreditFlag(true);
    setRefundedCreditsVisibility(true);
    setTimeout(() => setRefundedCreditsVisibility(false), 5000);
  };

  const getRefundTime = () => {
    const lastRefundTime = getLastRefundTime(connectStatus);
    if (!lastRefundTime) return null;
    if (isRefundedCreditsVisible) return <div>{`Refunded Time: ${lastRefundTime}`}</div>;
    return null;
  };

  const getAvailableContactHeader = () => {
    return isCandidateRequestStatusSuccessful ? (
      <div>
        <FormattedMessage {...candidateDetailMessage.contactNotValidLabel} />
      </div>
    ) : (
      <div className={styles.availableContactText}>
        <FormattedMessage {...candidateDetailMessage.contactsAvailableLabel} />
      </div>
    );
  };

  const getRequestRefundButton = () => {
    const isButtonDisabled = !checkIfAnyNonRefundedPulledContactsExists(connectStatus);
    return isCandidateRequestStatusSuccessful ? (
      <div className={classNames(styles.disabledRefundRequestButton, { [styles.disabledButton]: isButtonDisabled })}>
        <Button type="link" onClick={requestCredit} disabled={isButtonDisabled} className={styles.linkButton}>
          <span>
            <FormattedMessage {...candidateDetailMessage.requestRefundButton} />
          </span>
        </Button>
      </div>
    ) : null;
  };

  const getCreditRefundTextWithIcon = () => {
    return isRefundedCreditsVisible && creditsRefunded[candidate.PersonId] ? (
      <div className={styles.refundedCredit}>
        <Icon type="check-circle" />
        {creditsRefunded[candidate.PersonId]} <FormattedMessage {...candidateDetailMessage.credisRefundedLabel} />
      </div>
    ) : null;
  };

  const getCreditRefundHeader = () => {
    return requestCreditFlag ? (
      <div className={styles.creditsRefundedHeader}>
        {getAvailableContactHeader()}
        {getRequestRefundButton()}
        {getCreditRefundTextWithIcon()}
        {getRefundTime()}
      </div>
    ) : (
      <div>
        <div className={styles.pullRequestConfirmationContainer}>
          <div className={styles.invalidInformationConfirmation}>
            <FormattedMessage {...candidateDetailMessage.confirmRefundRequestLabel} />
          </div>
          <div className={styles.refundRequestButtonContainer}>
            <Button className={styles.refundRequestButton} onClick={requestCredit}>
              <FormattedMessage {...candidateDetailMessage.cancelButton} />
            </Button>
            <Button type="primary" className={styles.refundRequestButton} onClick={onConfirmCreditRequest}>
              <FormattedMessage {...candidateDetailMessage.confirmButton} />
            </Button>
          </div>
        </div>
        <Alert
          className={styles.refundRequestAlert}
          message="All the communications related to selected contacts will be deleted when the refund request is complete"
          type="warning"
          showIcon
        />
      </div>
    );
  };

  const emails = connectStatus?.Contact?.Emails ?? [];
  const handleButtonClick = () => setEmailSeeMore(!emailSeeMore);
  const buttonName = emailSeeMore ? (
    `+${emails.length - maxContactToBeShownInitially}`
  ) : (
    <FormattedMessage {...candidateDetailMessage.showLessButton} />
  );
  const isShowButtonVisible = emails?.length > maxContactToBeShownInitially;

  const showButton = isShowButtonVisible ? (
    <span>
      <span className={styles.seeMoreButton} onClick={handleButtonClick} role="presentation">
        {buttonName}
      </span>
      &nbsp;|&nbsp;
    </span>
  ) : null;

  const handlePhoneSeeMoreClick = () => setPhoneSeeMore(!phoneSeeMore);
  const showMorePhoneButtonName = phoneSeeMore ? (
    `+${phones.length - maxContactToBeShownInitially}`
  ) : (
    <FormattedMessage {...candidateDetailMessage.showLessButton} />
  );
  const isShowMorePhoneVisible = phones?.length > maxContactToBeShownInitially;

  const showMorePhoneButton = isShowMorePhoneVisible ? (
    <span>
      <span className={styles.seeMoreButton} onClick={handlePhoneSeeMoreClick} role="presentation">
        {showMorePhoneButtonName}
      </span>
      &nbsp;|&nbsp;
    </span>
  ) : null;

  const getAddEmailModal = () => {
    return isAddEmailFormVisible ? (
      <AddEmail
        addEmail={addEmail}
        height={21}
        isAddEmailFormVisible={isAddEmailFormVisible}
        setAddEmailFormVisibility={setAddEmailFormVisibility}
        emails={emails}
      />
    ) : null;
  };

  const getAddPhoneModal = () => {
    return isAddPhoneFormVisible ? (
      <AddPhoneNumber
        addPhone={addPhone}
        height={21}
        isAddPhoneFormVisible={isAddPhoneFormVisible}
        setAddPhoneFormVisibility={setAddPhoneFormVisibility}
        phones={phones}
      />
    ) : null;
  };

  const availableContactInformationHeader = areContactsAvailable ? (
    getCreditRefundHeader()
  ) : (
    <div className={styles.exclamationIconAndText}>
      <Icon type="exclamation-circle" className={styles.exclamationIcon} />
      <div className={styles.title} data-testid="no-contacts-available">
        <FormattedMessage {...candidateDetailMessage.noContactsAvailableLabel} />
      </div>
    </div>
  );

  const shrinkContactSectionButtonName = isDetailsVisible ? (
    <FormattedMessage {...candidateDetailMessage.showLessOption} />
  ) : (
    <FormattedMessage {...candidateDetailMessage.showMoreOption} />
  );

  return (
    <div className={styles.contactDetails}>
      <div className={styles.contactDetailsContent}>
        <div>{availableContactInformationHeader}</div>
        {isDetailsVisible ? (
          <div>
            <div className={styles.email}>
              <div className={styles.title}>
                <FormattedMessage {...candidateDetailMessage.emailLabel} />: &nbsp;
              </div>
              <div className={styles.emailContainer}>
                {getEmails()}
                {getAddEmailModal()}
                <div>
                  <span>{showButton}</span>
                  <span className={styles.addOption} role="presentation" onClick={onEmailAddClick}>
                    <FormattedMessage {...candidateDetailMessage.addButtonLabel} />
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.phone}>
              <div className={styles.title}>
                <FormattedMessage {...candidateDetailMessage.phoneLabel} />: &nbsp;
              </div>
              <div className={styles.phoneContainer}>
                {getPhones()}
                {getAddPhoneModal()}
                <div className={styles.phoneActionButtons}>
                  <span>{showMorePhoneButton}</span>
                  <span className={styles.addOption} role="presentation" onClick={onPhoneAddClick}>
                    <FormattedMessage {...candidateDetailMessage.addButtonLabel} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.expand}>
        <div role="presentation" onClick={onExpand} className={styles.expandIcon}>
          <Button type="link" className={styles.seeMore}>
            {shrinkContactSectionButtonName}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(Form.create()(AvailableContactInformation));
export { AvailableContactInformation as AvailableContactInformationWithoutInjectIntl };
