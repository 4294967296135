import React from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { purifySelectedOptions } from '../../Utils/SelectComponentUtility';
import EmploymentContext from './EmploymentContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const ExcludedCompany = props => {
  const { initialValue } = props;
  const { form } = React.useContext(EmploymentContext);
  return (
    <Form.Item>
      {form.getFieldDecorator('ExcludedCompanies', {
        initialValue,
      })(
        <Select
          mode="tags"
          key="ExcludedCompanies"
          placeholder={<FormattedMessage {...message.addCompanyLabel} />}
          style={{ width: '100%' }}
          dropdownStyle={{ display: 'none' }}
          onSelect={() => purifySelectedOptions(form, 'PreferredCompany')}
        />
      )}
    </Form.Item>
  );
};

export default ExcludedCompany;
