import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Modal, Button, Icon } from 'antd';
import styles from './CloseMailAndMessageComposeWindowModal.module.scss';
import message from '../../../Containers/CandidateDrawer/messages';

export default function CloseMailAndMessageComposeWindowModal(props) {
  const { isCloseModalVisible, onCloseComposeWindow, setCloseModalVisibility, context } = props;

  const onCancel = () => {
    setCloseModalVisibility(false);
  };

  const mediaDisplayName = context.toLowerCase() === 'message' ? 'text' : context;

  const footerButtons = (
    <div className={styles.actionButtons}>
      <Button shape="round" key="back" onClick={onCancel}>
        <FormattedMessage {...message.cancelButton} />
      </Button>
      <Button shape="round" key="submit" onClick={onCloseComposeWindow} type="primary">
        <FormattedMessage {...message.yesButton} />
      </Button>
    </div>
  );

  const upperCaseMediaName = _.capitalize(mediaDisplayName);

  return (
    <Modal
      title={<FormattedMessage {...message.closeComposerTitle} values={{ upperCaseMediaName }} />}
      visible={isCloseModalVisible}
      onCancel={onCancel}
      className={styles.closeModal}
      footer={[footerButtons]}
      zIndex={2501}
      maskClosable={false}
    >
      <div className={styles.modalContent}>
        <div className={styles.iconDiv}>
          <Icon type="exclamation-circle" />
        </div>
        <div className={styles.message}>
          <FormattedMessage {...message.closeComposerConfirmation} values={{ mediaDisplayName }} />
        </div>
      </div>
    </Modal>
  );
}
