import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import SubSegmentSelectBox from './SubSegmentSelectBox';
import styles from './SubSegmentStyles.module.scss';
import { updateSubSegmentData, totalCandidateCount } from './AddCandidateToSegmentModal';
import messages from '../ManualSearchV2/ManualSearchMessages';

const SubSegmentSelectContainer = props => {
  const [subSegmentList, setSubsegmentList] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(2); // Initializing state with 2 to start fetching from the second page.

  const {
    fetchSubSegments,
    segmentId,
    subsegmentList,
    setSubsegmentCheckbox,
    subSegmentCheckbox,
    onChangeForm,
    setSelectedSubsegmentId,
    selectedSubsegmentId,
    mode,
  } = props;

  const { subSegments, total } = subsegmentList;
  const selectStyle = {
    width: '51%',
    marginTop: '10px',
  };

  React.useEffect(() => {
    if (fetchSubSegments) fetchSubSegments(segmentId, null, 1, 10, false);
  }, [fetchSubSegments, segmentId]);

  React.useEffect(() => {
    if (Array.isArray(subSegments)) {
      if (subSegmentList.length === 0 || subSegments.length === 0) setSubsegmentList(subSegments);
      else {
        const newSubsegmentCandidateCount = totalCandidateCount(subSegments);
        if (newSubsegmentCandidateCount !== subsegmentCandidateCount || subSegmentList.length !== total) {
          const newSubsegmentData = updateSubSegmentData(subSegmentList, subSegments);
          setSubsegmentList(newSubsegmentData);
        }
      }
    }
  }, [subSegments]);

  const subsegmentCandidateCount = totalCandidateCount(subSegmentList);

  const onSelectScroll = e => {
    const { target } = e;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 5 && total > subSegmentList.length) {
      fetchSubSegments(segmentId, null, pageNumber, 10);
      setPageNumber(pageNumber + 1);
    }
  };

  const onChangeCheckbox = e => {
    onChangeForm({ id: 'IsSubSegmentChecked', value: e.target.checked });
    setSubsegmentCheckbox(e.target.checked);
  };

  const handleSubSegmentOption = value => {
    onChangeForm({ id: 'SubSegmentId', value });
    setSelectedSubsegmentId(value);
  };
  const extraWidth = `${subsegmentCandidateCount}`.length < 3 ? 20 : 10;

  return (
    <>
      <Checkbox
        style={{ color: '#1f2730', paddingTop: '10px' }}
        checked={subSegmentCheckbox}
        onChange={e => {
          onChangeCheckbox(e);
        }}
        disabled={mode === 'update'}
      >
        <FormattedMessage {...messages.subSegmentOptionalLabel} />
      </Checkbox>
      {subSegments && subSegmentCheckbox ? (
        <div className={styles.subsegmentSelectContainer}>
          <SubSegmentSelectBox
            selectStyle={selectStyle}
            handleSubSegmentOption={handleSubSegmentOption}
            onPopupScroll={onSelectScroll}
            subSegmentList={subSegmentList}
            subsegmentCandidateCount={subsegmentCandidateCount}
            extraWidth={extraWidth}
            selectedSubsegmentId={selectedSubsegmentId}
            isCandidateAssociationVisible={false}
            mode={mode}
          />
        </div>
      ) : null}
    </>
  );
};

export default SubSegmentSelectContainer;
