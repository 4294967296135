import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import cleanSet from 'clean-set';
import { Modal, Button, Form, Input, Select, DatePicker, Checkbox, Switch, Spin, InputNumber, message } from 'antd';
import styles from './ScoutingAgent.module.scss';
import { getSourceName } from '../../../Utils/SourceUtils';
import { getIsJobActivationAllowed, getClickedSources, updateIsClicked } from '../../../Utils/JobUtils';
import { getJobDetails } from '../../../Reducers/JobReducer';
import JobPortals from './JobPortals';
import { getConfig, getWhiteLabelInformation, getConfiguredSources } from '../../../Reducers/ConfigReducer';
import * as scoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getLocalDateFormat } from '../../../Utils/RelativeTimeCalculator';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import {
  getScoutingAgentModalVisiblityStatus,
  getCurrentScoutingAgent,
  getSelectedScoutingAgent,
  getScoutingAgentWarningModalVisiblityStatus,
  getScoutingAgentConfig,
  getScoutingAgentsofEnabledJobs,
} from '../../../Reducers/ScoutingAgentReducer';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import SubSegmentSelectContainer from '../../SubSegment/SubSegmentSelectContainer';
import { fetchSubSegments } from '../../../Actions/SubSegmentActions';
import { getSubSegmentListofSegment } from '../../../Reducers/SubsegmentReducer';
import messages from '../../ManualSearchV2/ManualSearchMessages';

const { Item } = Form;
const { Option } = Select;

const mapDispatchToProps = {
  setScoutingAgentModalVisibilityStatus: scoutingAgentActions.setScoutingAgentModalVisibility,
  setScoutingAgentWarningModalVisibilityStatus: scoutingAgentActions.setScoutingAgentWarningModalVisibility,
  fetchScoutingAgentFromAgentId: scoutingAgentActions.fetchScoutingAgentFromAgentId,
  setSelectedScoutingAgentId: scoutingAgentActions.setSelectedScoutingAgentIdAction,
  createScoutingAgent: scoutingAgentActions.createScoutingAgentAction,
  updateScoutingAgent: scoutingAgentActions.updateScoutingAgentAction,
  getScoutingAgentConfig: scoutingAgentActions.getScoutingAgentConfigAction,
  fetchJobScoutingAgent: scoutingAgentActions.fetchJobScoutingAgent,
  fetchSubSegmentsAction: fetchSubSegments,
};

const mapStateToProps = (state, props) => ({
  userConfig: getConfig(state),
  sources: getConfiguredSources(state),
  showVaultName: _.get(getConfig(state), ['ShowVaultName'], false),
  whiteLabelInfo: getWhiteLabelInformation(state),
  authorizedPortals: _.get(getConfig(state), ['AuthorizedPortals'], []),
  scoutingAgentModalVisibilityStatus: getScoutingAgentModalVisiblityStatus(state),
  scoutingAgentWarningModalVisibilityStatus: getScoutingAgentWarningModalVisiblityStatus(state),
  selectedAgentId: getSelectedScoutingAgent(state),
  currentScoutingAgentData: getCurrentScoutingAgent(state),
  getScoutingAgentCreateApiStatus: getApiStatus(state, 'createScoutingAgentApi'),
  getScoutingAgentCreateLoadApiStatus: getApiStatus(state, 'scoutingAgentFromAgentIdApiStatus'),
  currentJobDetails: getJobDetails(state, props.jobId),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  activeScoutingAgentCount: getScoutingAgentsofEnabledJobs(state, props.jobId),
  currentUserDetails: getCurrentUserDetails(state),
  featureToggleList: getFeatureToggleList(state),
  subsegmentList: getSubSegmentListofSegment(state, props.jobId),
});

const ScoutingAgentModal = props => {
  const {
    sources,
    showVaultName,
    whiteLabelInfo,
    authorizedPortals,
    currentJobDetails: job,
    userConfig,
    scoutingAgentModalVisibilityStatus: modalStatus,
    setScoutingAgentModalVisibilityStatus: setModalStatus,
    setScoutingAgentWarningModalVisibilityStatus: setWarningModalStatus,
    updateScoutingAgent,
    jobId,
    getScoutingAgentCreateApiStatus,
    currentScoutingAgentData,
    fetchScoutingAgentFromAgentId,
    createScoutingAgent,
    getScoutingAgentCreateLoadApiStatus,
    setSelectedScoutingAgentId,
    selectedAgentId,
    getCriteria,
    candidateContext,
    scoutingAgentConfig,
    activeScoutingAgentCount,
    fetchJobScoutingAgent,
    currentUserDetails,
    featureToggleList,
    subsegmentList,
    fetchSubSegmentsAction,
  } = props;

  const isNonInternalPortalForSegmentEnabled = featureToggleList.AllowNonInternalPortalsForSegment.IsEnabled;
  const [validationData, setValidationData] = React.useState({
    MinScoutingAgentLimit: 0,
    MaxScoutingAgentLimit: { JobJobMaxLimit: 0, SegmentMaxLimit: 0 },
    MinScoutingAgentDaysLimit: 0,
    MaxScoutingAgentDaysLimit: 0,
    DefaultEndDateTimeSpanInDays: 0,
  });
  const [subSegmentCheckbox, setSubsegmentCheckbox] = React.useState(false);
  const [selectedSubsegmentId, setSelectedSubsegmentId] = React.useState(null);

  const defaultEndDays = validationData.DefaultEndDateTimeSpanInDays;
  const defaultMaxCandidateLimitValue = validationData.MinScoutingAgentLimit;
  const defaultEndDateLimit = moment().add(defaultEndDays, 'days');
  const defaultScoutingAgentValue =
    candidateContext === 'segment'
      ? `${job.JobTitle}-${currentUserDetails.FirstName}`
      : `${job.Client}-${job.JobId}-${currentUserDetails.FirstName}`;
  const defaultScoutingAgentName = defaultScoutingAgentValue.substring(0, 45);
  const [scoutingAgentForm, setScoutingAgentForm] = React.useState({
    name: defaultScoutingAgentName,
    maxCandidate: defaultMaxCandidateLimitValue,
    frequencyPerDay: 1,
    endDate: defaultEndDateLimit,
    NotifySharedUsers: false,
    AutoDownload: candidateContext === 'segment',
  });
  const [portalStatusChangeCount, setPortalStatusChangeCount] = React.useState(0);
  const [jobActivationStatus, setJobActivation] = React.useState({});

  const [apiData, setApiData] = React.useState({});
  const [agentId, setAgentId] = React.useState(null);
  const currentJobActivationStatus = _.get(job, ['aryaState'], {});
  React.useEffect(() => {
    setValidationData(scoutingAgentConfig);
  }, [scoutingAgentConfig]);

  React.useEffect(() => {
    if (Object.keys(currentScoutingAgentData).length > 0 && modalStatus?.mode === 'update') {
      const endDate = moment(currentScoutingAgentData.EndDate);
      const { Sources } = currentScoutingAgentData;
      const updatedJobActivationStatus = updateIsClicked(jobActivationStatus, Sources);
      setJobActivation(updatedJobActivationStatus);
      setApiData({
        agentName: currentScoutingAgentData.AgentName,
        endDate,
        maxCandidate: currentScoutingAgentData.CandidateMaxLimit,
        NotifySharedUsers: currentScoutingAgentData.NotifySharedUsers,
        AutoDownloadCandidates: currentScoutingAgentData.AutoDownloadCandidates,
        frequencyPerDay: currentScoutingAgentData.FrequencyPerDay,
        createdTime: currentScoutingAgentData.CreatedTime,
      });
      updateScoutingAgentForm(currentScoutingAgentData);
    }
    if (modalStatus?.mode === 'new') updateScoutingAgentForm();
  }, [currentScoutingAgentData, selectedAgentId, modalStatus?.mode]);

  React.useEffect(() => {
    if (!agentId) updateScoutingAgentForm();
    if (selectedAgentId) {
      fetchScoutingAgentFromAgentId(selectedAgentId);
      setAgentId(selectedAgentId);
      setSelectedScoutingAgentId(null);
    } else {
      setApiData({});
    }
  }, [selectedAgentId, job, fetchScoutingAgentFromAgentId, setSelectedScoutingAgentId, agentId]);

  const filterInternalPortals = data => {
    return Object.entries(data)
      .filter(([key, value]) => value?.Source?.Portal === 'Internal')
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  };

  React.useEffect(() => {
    let tempJobActivationStatus = { ...currentJobActivationStatus };
    if (!isNonInternalPortalForSegmentEnabled && candidateContext === 'segment') {
      tempJobActivationStatus = filterInternalPortals(tempJobActivationStatus);
    }
    if (Object.keys(tempJobActivationStatus).length > 0) {
      Object.values(tempJobActivationStatus).forEach(sourceActivationStatus => {
        const sourceName = getSourceName(sourceActivationStatus.Source, false).toLowerCase();
        tempJobActivationStatus[sourceName] = cleanSet(tempJobActivationStatus[sourceName], ['isClicked'], true);
      });
      setJobActivation(tempJobActivationStatus);
    }
  }, [currentJobActivationStatus]);

  const updateScoutingAgentForm = formData => {
    const tempScoutingAgentForm = { ...scoutingAgentForm };
    if (typeof formData === 'object') {
      const endDate = moment(formData.EndDate);
      tempScoutingAgentForm.name = formData.AgentName;
      tempScoutingAgentForm.maxCandidate = formData.CandidateMaxLimit;
      tempScoutingAgentForm.frequencyPerDay = formData.FrequencyPerDay;
      tempScoutingAgentForm.endDate = endDate;
      tempScoutingAgentForm.NotifySharedUsers = formData.NotifySharedUsers;
      tempScoutingAgentForm.AutoDownload = formData.AutoDownloadCandidates;
      if (candidateContext === 'segment' && formData.SubSegmentId) {
        setSubsegmentCheckbox(true);
        setSelectedSubsegmentId(formData.SubSegmentId);
        tempScoutingAgentForm.IsSubSegmentChecked = true;
        tempScoutingAgentForm.SubSegmentId = formData.SubSegmentId;
      } else {
        setSubsegmentCheckbox(false);
        setSelectedSubsegmentId(null);
        tempScoutingAgentForm.IsSubSegmentChecked = false;
        tempScoutingAgentForm.SubSegmentId = null;
      }
    } else {
      tempScoutingAgentForm.name = defaultScoutingAgentName;
      tempScoutingAgentForm.maxCandidate = defaultMaxCandidateLimitValue;
      tempScoutingAgentForm.frequencyPerDay = 1;
      tempScoutingAgentForm.endDate = defaultEndDateLimit;
      tempScoutingAgentForm.NotifySharedUsers = false;
      tempScoutingAgentForm.AutoDownload = candidateContext === 'segment';
      tempScoutingAgentForm.IsSubSegmentChecked = false;
      tempScoutingAgentForm.SubSegmentId = null;
      if (candidateContext === 'segment') {
        setSubsegmentCheckbox(false);
        setSelectedSubsegmentId(null);
      }
    }
    setScoutingAgentForm(tempScoutingAgentForm);
  };

  const handlePortalSelect = sourceName => {
    let tempPortalStatuCount = portalStatusChangeCount;
    jobActivationStatus[sourceName].isClicked = !jobActivationStatus[sourceName].isClicked;
    if (jobActivationStatus[sourceName].isClicked === jobActivationStatus[sourceName].IsActivated) {
      tempPortalStatuCount -= 1;
    } else {
      tempPortalStatuCount += 1;
    }
    setJobActivation(jobActivationStatus);
    setPortalStatusChangeCount(tempPortalStatuCount);
  };

  const selectedPortals = () => {
    return Object.values(jobActivationStatus).filter(item => item.isClicked);
  };

  const onChangeForm = element => {
    const { value, id } = element;
    if (id) {
      let saveId = id;
      if (id === 'scouting_agent_name') saveId = 'name';
      if (id === 'scouting_agent_maxCandidate') saveId = 'maxCandidate';
      setScoutingAgentForm({ ...scoutingAgentForm, [saveId]: value });
    }
  };

  const getSources = () => getClickedSources(jobActivationStatus);

  const generateScoutingAgentPayload = () => {
    const {
      name,
      endDate,
      maxCandidate,
      frequencyPerDay,
      AutoDownload,
      NotifySharedUsers,
      IsSubSegmentChecked,
      SubSegmentId,
    } = scoutingAgentForm;
    const endDateWithModifiedTime = new Date(endDate);
    endDateWithModifiedTime.setHours(23, 30, 0, 0);
    const returnObj = {
      JobId: jobId,
      AgentName: name,
      EndDate: endDateWithModifiedTime.toISOString(),
      CandidateMaxLimit: maxCandidate,
      NotifySharedUsers,
      AutoDownloadCandidates: AutoDownload,
      FrequencyPerDay: frequencyPerDay,
    };
    if (getCriteria) {
      const Criteria = getCriteria();
      Criteria.Sources = getSources();
      returnObj.CandidateSearchCriteria = Criteria;
    }
    if (agentId) returnObj.Id = agentId;
    if (candidateContext === 'segment' && IsSubSegmentChecked && SubSegmentId) returnObj.SubSegmentId = SubSegmentId;
    return returnObj;
  };

  const onSaveAndScout = async () => {
    const payload = generateScoutingAgentPayload();
    const userSelectedSources = selectedPortals();
    if (activeScoutingAgentCount >= 5 && modalStatus?.mode !== 'update') {
      if (candidateContext === 'segment' && activeScoutingAgentCount >= 20) {
        setWarningModalStatus(true);
        message.error(<FormattedMessage {...messages.moreThan20ScoutingAgentsAreNotAllowedLabel} />);
      } else {
        setWarningModalStatus(true);
        message.error(<FormattedMessage {...messages.moreThan5ScoutingAgentsAreNotAllowedLabel} />);
      }
    } else if (userSelectedSources && userSelectedSources.length === 0)
      message.error(<FormattedMessage {...messages.pleaseSelectAPortalBeforeScoutLabel} />);
    else if (subSegmentCheckbox && !payload.SubSegmentId) {
      message.error(<FormattedMessage {...messages.pleaseSelectAnySubSegmentBeforeScoutLabel} />);
    } else if (modalStatus?.mode === 'update') {
      await updateScoutingAgent(payload);
    } else if (userSelectedSources && userSelectedSources.length > 0) {
      await createScoutingAgent(jobId, payload);
    }
    if (userSelectedSources && userSelectedSources.length > 0 && !(subSegmentCheckbox && !payload.SubSegmentId)) {
      setModalStatus(false);
      if (modalStatus?.mode === 'update') await fetchJobScoutingAgent(jobId);
    }
  };
  const bodyStyle = {
    padding: '10px 0 16px 0',
  };

  const isJobActivationAllowed = getIsJobActivationAllowed(userConfig, job?.HadActivated, job.HadOpened);
  return (
    <Modal
      destroyOnClose
      visible={modalStatus.show}
      className={styles.scoutingModal}
      bodyStyle={bodyStyle}
      zIndex={2005}
      onCancel={() => {
        setModalStatus(false);
      }}
      title={
        <p className={styles.header}>
          <FormattedMessage {...messages.saveCriteriaSetAScoutingAgentLabel} />
        </p>
      }
      footer={false}
      width={686}
      closable={false}
    >
      <Spin
        spinning={
          getScoutingAgentCreateApiStatus === 'INPROGRESS' || getScoutingAgentCreateLoadApiStatus === 'INPROGRESS'
        }
      >
        <div className={styles.modalContainer}>
          <WrappedScoutingAgentForm
            onChangeForm={onChangeForm}
            scoutingAgentForm={scoutingAgentForm}
            sources={
              !isNonInternalPortalForSegmentEnabled && candidateContext === 'segment'
                ? sources?.filter(source => source?.Portal?.toLowerCase() === 'internal')
                : sources
            }
            showVaultName={showVaultName}
            jobActivationStatus={jobActivationStatus}
            whiteLabelInfo={whiteLabelInfo}
            handlePortalSelect={handlePortalSelect}
            authorizedPortals={authorizedPortals}
            isJobActivationAllowed={isJobActivationAllowed}
            setModalStatus={setModalStatus}
            getScoutingAgentCreateApiStatus={getScoutingAgentCreateApiStatus}
            onSaveAndScout={onSaveAndScout}
            apiData={apiData}
            setApiData={setApiData}
            candidateContext={candidateContext}
            validationData={validationData}
            userConfig={userConfig}
            job={job}
            currentUserDetails={currentUserDetails}
            fetchSubSegmentsAction={fetchSubSegmentsAction}
            subsegmentList={subsegmentList}
            setSubsegmentCheckbox={setSubsegmentCheckbox}
            subSegmentCheckbox={subSegmentCheckbox}
            setSelectedSubsegmentId={setSelectedSubsegmentId}
            selectedSubsegmentId={selectedSubsegmentId}
            modalStatus={modalStatus}
            jobId={jobId}
          />
        </div>
      </Spin>
    </Modal>
  );
};

const ScoutingAgentForm = props => {
  const {
    onChangeForm,
    scoutingAgentForm,
    sources,
    showVaultName,
    jobActivationStatus,
    whiteLabelInfo,
    handlePortalSelect,
    authorizedPortals,
    isJobActivationAllowed,
    form,
    setModalStatus,
    onSaveAndScout,
    getScoutingAgentCreateApiStatus,
    apiData,
    candidateContext,
    validationData,
    userConfig,
    currentUserDetails,
    job,
    fetchSubSegmentsAction: fetchSubSegments,
    subsegmentList,
    setSubsegmentCheckbox,
    subSegmentCheckbox,
    setSelectedSubsegmentId,
    selectedSubsegmentId,
    modalStatus,
    jobId,
  } = props;
  const dateFormat = getLocalDateFormat();
  const { name, endDate, maxCandidate } = scoutingAgentForm;
  const { getFieldDecorator, setFieldsValue } = form;

  const { agentName, endDate: apiEndDate, maxCandidate: CandidateMaxLimit, createdTime } = apiData;
  let initialVal =
    candidateContext === 'segment'
      ? `${job.JobTitle}-${currentUserDetails.FirstName}`
      : `${job.Client}-${job.JobId}-${currentUserDetails.FirstName}`;
  if (initialVal.length > 50) {
    initialVal = initialVal.substring(0, 45);
  }
  React.useEffect(() => {
    setFieldsValue({
      name,
      frequencyEndDate: endDate,
      maxCandidate,
    });
  }, [name, endDate, maxCandidate, setFieldsValue]);
  React.useEffect(() => {
    if (Object.keys(apiData).length > 0 && agentName && apiEndDate && CandidateMaxLimit) {
      setFieldsValue({
        name: agentName,
        frequencyEndDate: apiEndDate,
        maxCandidate: CandidateMaxLimit,
      });
    }
  }, [CandidateMaxLimit, agentName, apiData, apiEndDate, setFieldsValue]);
  const handleSaveAndScout = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSaveAndScout();
      }
    });
  };

  const handleChange = e => {
    const { id, value } = e.target;
    if (id === 'scouting_agent_name') {
      setFieldsValue({
        name: value,
      });
    }
    if (id === 'scouting_agent_maxCandidate') {
      setFieldsValue({
        maxCandidate: value,
      });
    } else onChangeForm(e.target);
  };
  const disabledDate = current => {
    const createdDate = new Date(Date.now());
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(createdDate.getDate() + validationData.MaxScoutingAgentDaysLimit);
    return current < today || current > maxDate;
  };
  const maxCandidateLimit =
    candidateContext === 'segment'
      ? validationData.MaxScoutingAgentLimit.SegmentMaxLimit
      : validationData.MaxScoutingAgentLimit.JobMaxLimit;
  return (
    <Form id="scoutingAgentForm" layout="inline" onSubmit={handleSaveAndScout}>
      <Item className={styles.formItemFullWidth} required>
        {getFieldDecorator('name', {
          rules: [
            { required: true, message: <FormattedMessage {...messages.pleaseEnterAgentNameLabel} /> },
            {
              validator: (_, value) => {
                if (value.length > 50) return Promise.reject(new Error(`Agent name is too big`));
                return Promise.resolve();
              },
            },
          ],
        })(<Input id="name" onChange={handleChange} placeholder={initialVal} />)}
      </Item>
      <>
        <p className={styles.heading} style={{ paddingLeft: '24px' }}>
          <FormattedMessage {...messages.howFrequentlyDoYouWantToSearchCandidatesLabel} />
        </p>
        <div className={styles.frequencyContainer}>
          <div className={styles.selectDay}>
            <Select
              onChange={val => onChangeForm({ id: 'frequencyPerDay', value: val })}
              value={scoutingAgentForm.frequencyPerDay}
              getPopupContainer={node => node.parentNode}
              disabled={candidateContext === 'segment'}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </div>
          <div className={styles.frequencyText}>
            <FormattedMessage {...messages.timesPerDayUntilLabel} />
          </div>
          <div className={styles.selectFrequent}>
            <Item>
              {getFieldDecorator('frequencyEndDate', {
                rules: [{ required: true, message: <FormattedMessage {...messages.pleaseEnterEndDateLabel} /> }],
              })(
                <DatePicker
                  getCalendarContainer={node => node.parentNode}
                  disabledDate={disabledDate}
                  style={{ maxWidth: 120 }}
                  allowClear={false}
                  format={dateFormat}
                  showToday={false}
                  onChange={date => onChangeForm({ id: 'endDate', value: date })}
                />
              )}
            </Item>
          </div>
        </div>
      </>
      <div className={styles.maxCandidate}>
        <p className={styles.heading}>
          {' '}
          <FormattedMessage {...messages.setMaxNumberOfCandidatesForThisAgentLabel} />
        </p>
        <Item>
          {getFieldDecorator('maxCandidate', {
            rules: [
              { required: true, message: <FormattedMessage {...messages.pleaseEnterMaximumCandidateLabel} /> },
              {
                validator: (_, value) => {
                  const val = parseInt(value, 10);

                  if (val > maxCandidateLimit)
                    return Promise.reject(new Error(`max candidate cannot be greater than ${maxCandidateLimit}`));
                  if (val < validationData.MinScoutingAgentLimit)
                    return Promise.reject(
                      new Error(`max candidate cannot be smaller than ${validationData.MinScoutingAgentLimit}`)
                    );
                  if (value && !Number.isInteger(value)) {
                    return Promise.reject(new Error(`Please enter the value in numbers`));
                  }
                  return Promise.resolve();
                },
              },
            ],
          })(
            <InputNumber
              id="maxCandidate"
              style={{ width: '100px' }}
              onChange={value => onChangeForm({ id: 'maxCandidate', value })}
            />
          )}
        </Item>
      </div>
      <div style={{ paddingLeft: '24px' }}>
        <JobPortals
          sources={sources}
          userConfig={userConfig}
          sourceTitle={<FormattedMessage {...messages.whichPortalsLabel} />}
          showVaultName={showVaultName}
          currentJobActivationStatus={jobActivationStatus}
          whiteLabelInfo={whiteLabelInfo}
          handlePortalSelect={handlePortalSelect}
          authorizedPortals={authorizedPortals}
          isJobActivationAllowed={isJobActivationAllowed}
        />
      </div>

      {candidateContext !== 'segment' ? (
        <div className={styles.notifyUser}>
          <h3>
            <FormattedMessage {...messages.notifyToSharedUsersOnCandidateSourcingLabel} />
          </h3>
          <Switch
            checked={scoutingAgentForm.NotifySharedUsers}
            onChange={checked => onChangeForm({ id: 'NotifySharedUsers', value: checked })}
          />
        </div>
      ) : null}
      <Checkbox
        style={{ paddingLeft: '24px' }}
        defaultChecked={candidateContext === 'segment'}
        disabled={candidateContext === 'segment' || !validationData.AutoDownloadEnabled}
        onChange={e => onChangeForm({ id: 'AutoDownload', value: e.target.checked })}
        checked={scoutingAgentForm.AutoDownload}
      >
        <FormattedMessage {...messages.autoDownloadCandidatesLabel} />
      </Checkbox>
      {candidateContext === 'segment' ? (
        <>
          <div className={styles.segmentScoutedContainer}>
            <FormattedMessage {...messages.addCandidateToLabel} />
          </div>
          <div className={styles.segmentScoutedChild}>
            <Checkbox className={styles.segmentScoutedFirstChild} defaultChecked disabled>
            <FormattedMessage {...messages.includedCandidatesLabel} />{' '}
            </Checkbox>
            <div>
              <SubSegmentSelectContainer
                fetchSubSegments={fetchSubSegments}
                segmentId={job.JobId || jobId}
                subsegmentList={subsegmentList}
                setSubsegmentCheckbox={setSubsegmentCheckbox}
                subSegmentCheckbox={subSegmentCheckbox}
                onChangeForm={onChangeForm}
                setSelectedSubsegmentId={setSelectedSubsegmentId}
                selectedSubsegmentId={selectedSubsegmentId}
                mode={modalStatus?.mode}
              />
            </div>
          </div>
        </>
      ) : null}
      <div className={styles.divider}></div>
      <div className={styles.actionButtons}>
        <Button shape="round" key="back" onClick={() => setModalStatus(false)}>
        <FormattedMessage {...messages.cancelLabel} />
        </Button>
        <Button
          loading={getScoutingAgentCreateApiStatus === 'INPROGRESS'}
          shape="round"
          type="primary"
          htmlType="submit"
        >
           <FormattedMessage {...messages.saveAndScoutLabel} />
        </Button>
      </div>
    </Form>
  );
};

const WrappedScoutingAgentForm = Form.create({ name: 'scouting_agent' })(ScoutingAgentForm);
export default connect(mapStateToProps, mapDispatchToProps)(ScoutingAgentModal);

export { ScoutingAgentModal as ScoutingAgentModalWithoutStore };
