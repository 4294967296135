import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormSelectComponent } from '../../../../Utils/FormComponentUtils';
import { formValuesMapper } from '../../../../Utils/MapperUtils';
import { getCommaSplittedString } from '../../QuickSearchFormUtils';
import SocialInputs from '../../../../Data/AdvancedOptions/Social/SocialInputs.json';
import SocialOutputs from '../../../../Data/AdvancedOptions/Social/SocialOptions.json';
import '../AdvancedOptions.scss';
import messages from '../../../../Containers/ManualSearchV2/ManualSearchMessages';

export default function SocialAdvanceOptions(props) {
  const { SocialSourceNameOptions: socialSourceNameOptions } = SocialOutputs;
  const { SocialSourceNameParams: socialSourceNameParams } = SocialInputs;
  const label= <FormattedMessage {...messages.sourceNameLabel} />;
  const { form, defaultSettings, fieldDecoratorValue, placeholder } = props;
  const { SocialSourceName: socialSourceName } = defaultSettings;

  return (
    <div className="quicksearch-advanced-options careerbuilder-edge-advanced-options">
      <FormSelectComponent
        {...socialSourceNameParams}
        label={label}
        fieldDecoratorValue={fieldDecoratorValue}
        placeholder={placeholder}
        form={form}
        initialValue={getCommaSplittedString(socialSourceName)}
        inputValues={formValuesMapper(socialSourceNameOptions)}
      />
    </div>
  );
}
