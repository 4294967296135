import React from 'react';
import { Input, Form } from 'antd';
import { injectIntl } from 'react-intl';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';
import PersonalInformationContext from './PersonalInformationContext';

const { Item } = Form;

function Name(props) {
  const { initialValue, isNameMasked,intl } = props;
  const { form } = React.useContext(PersonalInformationContext);

  return (
    <Item colon={false}>
      {form.getFieldDecorator('Name', {
        initialValue,
      })(<Input style={{ overflow: 'hidden' }} placeholder={intl.formatMessage({ ...message.enterNameLabel })} disabled={isNameMasked} />)}
    </Item>
  );
}


export default (injectIntl(Form.create()(Name)));
export { Name as NameWithoutIntl };
