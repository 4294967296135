import React from 'react';
import { Checkbox, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import EmploymentContext from './EmploymentContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const PreferredCompanyCheckbox = props => {
  const { isCurrentCompanyInitialValue, isPastCompanyInitialValue } = props;
  const { form, isDisable } = React.useContext(EmploymentContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '14px' }}>
      <Form.Item>
        {form.getFieldDecorator('IsPastCompany', {
          valuePropName: 'checked',
          initialValue: isPastCompanyInitialValue,
        })(<Checkbox disabled={isDisable}><FormattedMessage {...message.searchInPastCompanyLabel} /></Checkbox>)}
      </Form.Item>
      <Form.Item>
        {form.getFieldDecorator('IsCurrentCompany', {
          valuePropName: 'checked',
          initialValue: isCurrentCompanyInitialValue,
        })(<Checkbox disabled={isDisable}><FormattedMessage {...message.searchInCurrentCompanyLabel} /></Checkbox>)}
      </Form.Item>
    </div>
  );
};

export default PreferredCompanyCheckbox;
