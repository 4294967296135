import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './PersonalInformationSection.module.scss';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import PersonalInformationWrapper from '../../../../CompoundComponents/PersonalInformationWrapper/PersonalInformationWrapper';
import Name from '../../../../CompoundComponents/PersonalInformationWrapper/Name';
import Phone from '../../../../CompoundComponents/PersonalInformationWrapper/Phone';
import Email from '../../../../CompoundComponents/PersonalInformationWrapper/Email';
import TextWithInfoIcon from '../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';
import message from '../../../ManualSearchV2/ManualSearchMessages';

function PersonalInformationSection(props) {
  const { form, defaultFormValues } = props;
  const onClear = () => {
    form.setFieldsValue({
      Name: undefined,
      Email: undefined,
      Mobile: undefined,
    });
  };
  return (
    <div className={styles.personalInfoSection}>
      <div className={styles.personalInfoHeader}>
        <TextWithInfoIcon
          text={<div className={styles.personalInfoLabel}>Personal Details</div>}
          tooltipTitle="Personal details are supported by Internal & Social (Social supports candidate name only)"
        />
        <div className={styles.personalInfoClear}>
          <ClearAllButton onClear={onClear} />
        </div>
      </div>
      <div className={styles.personalInfoForm}>
        <PersonalInformationWrapper form={form}>
          <div className={styles.personalInformationSectionRow}>
            <div className={styles.formItem}>
              <div className={styles.formLabel}>Name</div>
              <Name initialValue={defaultFormValues?.Name} />
            </div>
            <div className={styles.formItem}>
              <div className={styles.formLabel}>Email Address</div>
              <Email initialValue={defaultFormValues?.Email} />
            </div>
          </div>
          <div className={styles.personalInformationSectionRow}>
            <div className={styles.formItem}>
              <div className={styles.formLabel}>Phone</div>
              <Phone initialValue={defaultFormValues?.Mobile} />
            </div>
          </div>
        </PersonalInformationWrapper>
      </div>
    </div>
  );
}

export default PersonalInformationSection;
