import React from 'react';
import { Icon,Form } from 'antd';
import { injectIntl } from 'react-intl';
import InputSearch from '../../Components/InputSearch/InputSearch';
import MustHavesContext from './MustHavesContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

 function MustHavesInput(props) {
  const { shouldAddClassname, disabledButtonClassname, enabledButtonClassname,intl } = props;
  const { addMustHave, mustHaveInputValue, onMustHavesChange, mustHaveInputRef } = React.useContext(MustHavesContext);

  const mustHaveClassname = !mustHaveInputValue.trim() ? disabledButtonClassname : enabledButtonClassname;
  const additionalSkillInputClassname = shouldAddClassname ? mustHaveClassname : '';

  return (
    <InputSearch
      mustHaveInputRef={mustHaveInputRef}
      placeholder={intl.formatMessage(message.addSkillsKeywordsLabel)}
      value={mustHaveInputValue}
      enterButton={<Icon type="plus" style={{ display: 'flex' }} />}
      onSearch={addMustHave}
      onChange={onMustHavesChange}
      id="must-haves-input-field"
      shouldAddClassname={shouldAddClassname}
      additionalSkillInputClassname={additionalSkillInputClassname}
    />
  );
}

export default (injectIntl(Form.create()(MustHavesInput)));
export { MustHavesInput as MustHavesInputWithoutInjectIntl };
