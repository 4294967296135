import { Input, Button, Tooltip, Form } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import React from 'react';
import AddListModal from '../Modals/AddListModal';
import styles from './ListsHeader.module.scss';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import message from '../ListsMessages';

const mapStateToProps = state => ({
  createSegmentApiStatus: getApiStatus(state, 'createSegmentApiStatus'),
  featureToggleList: getFeatureToggleList(state),
});
function ListsHeader(props) {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const {
    form,
    createSegment,
    segmentDetails,
    createSegmentApiStatus,
    onSearch,
    onChange,
    searchTerm,
    onSegmentCreationCallback,
    featureToggleList,
    intl,
  } = props;
  const isSegmentCreationEnabled = featureToggleList?.SegmentCreation?.IsEnabled;
  const [inputText, setInputText] = React.useState('');
  const [characterLimit] = React.useState(200);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const onDescriptionChange = value => {
    setInputText(value);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setInputText('');
    form.setFieldsValue({ listName: undefined, listDescription: undefined });
  };
  const handleOk = async () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        segmentDetails.JobTitle = values.listName;
        segmentDetails.Description = values.listDescription && values.listDescription.trim();
        await createSegment(segmentDetails, onSegmentCreationCallback);
        setIsModalVisible(false);
      }
    });
  };
  return (
    <>
      <div className={styles.listSearch}>
        <div>
          <Input.Search
            size="default"
            placeholder={intl.formatMessage({ ...message.searchListLabel })}
            allowClear
            className={styles.listsInputSearch}
            onSearch={value => onSearch(value)}
            onChange={onChange}
            value={searchTerm}
          />
        </div>

        <Tooltip
          title={
            !isSegmentCreationEnabled ? intl.formatMessage({ ...message.youDoNotHavePermissionLabel }) : ''
          }
        >
          <Button type="primary" shape="round" onClick={showModal} disabled={!isSegmentCreationEnabled}>
            <FormattedMessage {...message.createSegmentLabel} />
          </Button>
        </Tooltip>
      </div>
      {isModalVisible ? (
        <AddListModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleOk={handleOk}
          title={<FormattedMessage {...message.createANewSegmentLabel} />}
          buttonName={<FormattedMessage {...message.createButtonLabel} />}
          form={form}
          segmentDetails={segmentDetails}
          isLoading={createSegmentApiStatus === 'INPROGRESS'}
          inputText={inputText}
          characterLimit={characterLimit}
          onDescriptionInputChange={onDescriptionChange}
          modalType="Segment"
        />
      ) : null}
    </>
  );
}

export default connect(mapStateToProps, null)(injectIntl(Form.create()(ListsHeader)));
export { ListsHeader };
