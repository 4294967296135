import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import { CheckIcon } from '../../../Icons/AryaIcons';
import SourceItemV2 from '../../../CompoundComponents/Sources/SourceItemV2';
import SourceItemName from '../../../CompoundComponents/Sources/SourceItemName';
import AdvancedSettingsPopover from '../../../CompoundComponents/Sources/AdvancedSettingsPopover';
import { getIsAdvancedSettingsEnabled } from '../../../Components/QuickSearchForm/QuickSearchFormUtils';
import styles from './ManualSearchSources.module.scss';
import SourcesContext from '../../../CompoundComponents/Sources/SourceContext';
import FilterIconWithTooltip from '../../../Components/Common/FilterIconWithTooltip/FilterIconWithTooltip';
import message from '../ManualSearchMessages';

function ManualSearchSourceItem(props) {
  const {
    isFilterApplied,
    isAdvanceSettingsButtonDisabled,
    sourcesSectionVisibility,
    source,
    candidateContext,
    isNonInternalPortalForSegmentEnabled,
    isSocialAdvanceSettingsEnabled,
    sourceName,
    sourceNamesSelected,
    intl,
  } = props;
  const isAdvanceSettingsEnabled = getIsAdvancedSettingsEnabled(source, isSocialAdvanceSettingsEnabled);
  const { isSourceAuthorized, onSourceSelect } = React.useContext(SourcesContext);

  const disabledStyle = isAdvanceSettingsButtonDisabled ? { cursor: 'not-allowed', opacity: 0.3 } : {};
  const filterIconStyle = { fontSize: 15, ...disabledStyle };
  const isSourceSelected = sourceNamesSelected.includes(sourceName) && isSourceAuthorized;
  const isInternalSelectedOnly = sourceNamesSelected?.length === 1 && sourceNamesSelected[0] === 'Leoforce';
  const isSourceAButtonDisabledForSgment =
    isInternalSelectedOnly && candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled;
  const isSourceButtonDisabled = !isSourceAuthorized || isSourceAButtonDisabledForSgment;

  const handleSelectableSourceClick = () => {
    if (isSourceButtonDisabled) return;
    onSourceSelect(sourceName);
  };

  return (
    <div
      className={classNames(styles.sourceWrapper, {
        [styles.sourceSelected]: isSourceSelected,
        [styles.selectionDisabled]: isSourceButtonDisabled,
      })}
      onClick={handleSelectableSourceClick}
    >
      <div className={styles.sourceItemWrapper}>
        <SourceItemV2
          candidateContext={candidateContext}
          isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        />
        {sourcesSectionVisibility ? <SourceItemName /> : null}
      </div>
      {sourcesSectionVisibility && isAdvanceSettingsEnabled ? (
        <div onClick={e => e.stopPropagation()}>
          <AdvancedSettingsPopover>
            <FilterIconWithTooltip
              fillColor={isFilterApplied && !isAdvanceSettingsButtonDisabled ? '#13c26b' : '#DADBDD'}
              title={intl.formatMessage({ ...message.filtersLabel })}
              isFilterApplied={isFilterApplied && !isAdvanceSettingsButtonDisabled}
              style={filterIconStyle}
              dotStyle={{ backgroundColor: 'red', top: '4px', left: '10px' }}
            />
          </AdvancedSettingsPopover>
        </div>
      ) : null}
      {isSourceSelected ? <CheckIcon style={{ position: 'absolute', top: '-4px', left: '-6px' }} /> : null}
    </div>
  );
}

export default injectIntl(Form.create()(ManualSearchSourceItem));
export { ManualSearchSourceItem as ManualSearchSourceItemWithoutInjectIntl };
