import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import InfoCircleIcon from '../../Icons/SuggestedIcons/InfoCircleIcon';
import styles from './BulkCandidatesCriteria.module.scss';
import jobMessage from '../JobForm/JobMessages';

export default function BulkCandidatesDownloadFilterInfo(props) {
  const {
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
    dripTemplate,
    workflowTemplate = false,
  } = props;
  const templateName = workflowTemplate ? 'Workflow' : 'Drip';
  if (!candidateDownloadFilterConfig && !candidateCommunicationFilterConfig) {
    return dripTemplate || workflowTemplate ? (
      <div className={styles.dripDisclaimerMessage}>
        <div>
          <InfoCircleIcon />
        </div>
        <b>
          <u>Note:</u>
        </b>{' '}
        {templateName} will stop once candidate responds
      </div>
    ) : null;
  }

  const downloadconfigurations = candidateDownloadFilterConfig?.FilterConfigurations;
  const commnunicationconfigurations = candidateCommunicationFilterConfig?.FilterConfigurations;
  let forbidenPortalsfromDownload;
  let forbidenPortalsfromCommunication;
  let forbidenStatesfromDownload;
  let forbidenStatesfromCommunication;
  if (downloadconfigurations?.length) {
    forbidenPortalsfromDownload = downloadconfigurations[0]?.ForbidenPortals;
    forbidenStatesfromDownload = downloadconfigurations[1]?.ForbidenStates?.map(forbidenState => forbidenState?.Name);
  }
  if (commnunicationconfigurations?.length) {
    forbidenPortalsfromCommunication = commnunicationconfigurations[0]?.ForbidenPortals;
    forbidenStatesfromCommunication = commnunicationconfigurations[0]?.ForbidenStates?.map(
      forbidenState => forbidenState?.Name
    );
  }
  let forbidenPortals;
  let forbidenStates;

  forbidenPortals = _.uniq((forbidenPortalsfromDownload ?? []).concat(forbidenPortalsfromCommunication ?? []));
  forbidenStates = _.uniq((forbidenStatesfromDownload ?? []).concat(forbidenStatesfromCommunication ?? []));

  forbidenPortals = forbidenPortals?.join(',') || '';
  forbidenStates = forbidenStates?.join(',') || '';

  return (
    <div className={styles.bulkOperationsCandidateCriteriaWrapper}>
      <div className={styles.bulkOperationsCandidateCriteriaInfoIcon}>
        <InfoCircleIcon />
      </div>
      <div className={styles.bulkOperationsCandidatesMain}>
        <div className={styles.bulkOperationsCandidateCriteria}>
          <b>
            <u>
              <FormattedMessage {...jobMessage.noteLabel} />:
            </u>
          </b>{' '}
          <FormattedMessage {...jobMessage.bulkOperationInfoMessage} />
        </div>
        <div className={styles.bulkOperationsCandidateCriteria}>
          <ul style={{ listStyle: 'disc', paddingInlineStart: '40px' }}>
            {forbidenPortals ? (
              <li>
                <FormattedMessage {...jobMessage.candidatesFrom} /> {forbidenPortals}
                <FormattedMessage {...jobMessage.jobBoardProfileDownloadInfo} />
              </li>
            ) : null}
            {forbidenStates ? (
              <li>
                <FormattedMessage {...jobMessage.forbiddenStateInfoMessage} /> {forbidenStates}
              </li>
            ) : null}
          </ul>
        </div>
        {dripTemplate || workflowTemplate ? (
          <div className={styles.bulkOperationsCandidateCriteria}>
            2. <FormattedMessage {...jobMessage.templateNameWillStopOnceCandidateRespondsLabel} values={{templateName}}/>
          </div>
        ) : null}
      </div>
    </div>
  );
}
