import React from 'react';
import { Input, Form } from 'antd';
import { injectIntl } from 'react-intl';
import { validateBooleanString } from '../../Utils/FormValidators';
import EducationContext from './EducationContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const { TextArea } = Input;

const { Item } = Form;
 function Specialization(props) {
  const { initialValue,intl } = props;
  const { form } = React.useContext(EducationContext);

  return (
    <Item colon={false}>
      {form.getFieldDecorator('Specialization', {
        initialValue,
        rules: [
          {
            validator: (rule, booleanString, callback) => validateBooleanString(rule, booleanString, callback, form),
          },
        ],
      })(
        <TextArea
          style={{ overflow: 'hidden' }}
          placeholder={intl.formatMessage({ ...message.financeOrRiskManagementAndBusinessAdministrationLabel })}
        />
      )}
    </Item>
  );
}


export default injectIntl(Form.create()(Specialization));
export { Specialization as SpecializationWithoutInjectIntl };
