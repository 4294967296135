import React from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import EmploymentContext from './EmploymentContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const ExcludedIndustry = props => {
  const { initialValue } = props;
  const { form, industries } = React.useContext(EmploymentContext);
  return (
    <Form.Item>
      {form.getFieldDecorator('ExcludedIndustries', {
        initialValue,
      })(
        <Select
          key="ExcludedIndustries"
          placeholder={<FormattedMessage {...message.selectIndustryLabel} />}
          mode="multiple"
          style={{ width: '100%' }}
          showArrow
          allowClear
        >
          {industries.map(industry => (
            <Select.Option key={industry} value={industry}>
              {industry}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default ExcludedIndustry;
