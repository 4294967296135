import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Popover, Button } from 'antd';
import CandidateSortingFilter from '../../../Components/CandidateSortingFilter/CandidateSortingFilter';
import { SortIcon } from '../../../Icons/AryaIcons';
import message from '../CandidateFiltersMessage';

function CandidateSortFilter(props) {
  const { candidates, applyCandidateFilter, activeTab, selectedFilter, setSelectedFilter } = props;
  const applySortingFilter = sortBy => {
    applyCandidateFilter({ modifiedFilters: { SortBy: sortBy }, invokationContext: 'sortFilter' });
  };

  const sortIconAdditionalStyles = candidates.length ? {} : { opacity: 0.3 };

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={
        candidates.length ? (
          <CandidateSortingFilter
            applySortingFilter={applySortingFilter}
            activeTab={activeTab}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        ) : null
      }
    >
      <Tooltip title={<FormattedMessage {...message.sortByLabel} />}>
        <Button className="controls-button" style={candidates.length ? {} : { cursor: 'not-allowed' }}>
          <SortIcon style={{ fontSize: '20px', ...sortIconAdditionalStyles }} />
        </Button>
      </Tooltip>
    </Popover>
  );
}

export default CandidateSortFilter;
